import Keycloak from 'keycloak-js';

export const keycloak = new Keycloak({
   realm: process.env.REACT_APP_KEYCLOAK_REALM,
   url: process.env.REACT_APP_KEYCLOAK_BASE_URL,
   clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,

});

const AuthKeycloak = (option) => {
   return keycloak.init({ onLoad: option });
}


export default AuthKeycloak;