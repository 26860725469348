import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { DisplayResourceName, PictureGallery } from "components";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectStatusCodesConf, selectResourcesTypesConf } from "redux/configurationSlice";
import { selectLocationDetails } from "redux/locationsSlice";
import { format } from "date-fns";


export default function AddSampleReport(props) {

    const { data, statusCodes, notes, timestamp, collector, locationId, pictures } = props.reportData;

    const theme = useTheme();
    const statusCodesConf = useSelector(selectStatusCodesConf).TSI;
    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const location = useSelector(state => selectLocationDetails(state, locationId));
    const entryDataArray = [];

    if (!!data) {
        for (let index = 0; index < data.length; index++) {
            const entryData = data[index];
            const resource = resourceTypesConf.find(el => el.key.toString() === entryData.resourceTypeKey);
            entryDataArray.push({
                resource: <DisplayResourceName resource={resource} />,
                value: entryData.value,
                unit: resource.unit,
                status: entryData.status
            });
        }
    }

    return (
        <Box >
            <Box>
                <Typography color="primary" variant='body2' sx={{ display: 'inline-block' }} ><FormattedMessage id='LOCATION' /></Typography>
                <Typography variant='body1' sx={{ display: 'inline', p: 1 }}>{location.name}</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
                <Typography color="primary" variant='body2' sx={{ display: 'inline-block' }} ><FormattedMessage id='TIMESTAMP' /></Typography>
                <Typography variant='body1' sx={{ display: 'inline', p: 1 }} >{format(timestamp, "yyyy-MM-dd HH:mm")}</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
                <Typography color="primary" variant='body2' sx={{ display: 'inline-block' }} ><FormattedMessage id='SAMPLE_LOG.COLLECTOR' /></Typography>
                <Typography variant='body1' sx={{ display: 'inline', p: 1 }} >{collector}</Typography>
            </Box>
            {statusCodes && statusCodes.TSI && statusCodes.TSI.length ?
                <Box sx={{ my: 1 }}>
                    <Typography color="primary" variant='body2' sx={{ mb: 1, display: 'inline-block' }}><FormattedMessage id="QUALITY_INDEX" /></Typography>
                    {statusCodes.TSI.map(status =>
                        <Box key={status.key} sx={{ ml: 1 }}>
                            <Typography variant='body1' sx={{ display: 'inline-block', color: theme.palette.text.secondary }}><FormattedMessage id={status.key} /></Typography>
                            <Typography variant='body1' sx={{ display: 'inline', p: 1 }}><FormattedMessage id={'SAMPLE_LOG.STATUS.TSI.' + statusCodesConf.find(el => el.key === status.value).value} /></Typography>
                        </Box>)}
                </Box> : null}

            {entryDataArray.length ?
                <Grid container direction={'row'} spacing={1} sx={{ mt: 1 }}>
                    <Typography color="primary" variant='body2' sx={{ width: '100%', pl: 1, mb: 1 }}><FormattedMessage id="MEASUREMENTS" /></Typography>
                    {entryDataArray.map((entry, index) =>
                        <Box key={index} sx={{ display: 'inline-block', pb: 2, px: 1 }}>
                            <Typography variant="subtitle2" display="block">{entry.resource}</Typography>
                            <Typography color="primary.main" variant="h4" display="inline">{entry.value}</Typography>
                            <Typography variant="body1" display="inline">{entry.unit}</Typography>
                            {entry.status && entry.status.length ? entry.status.map(status =>
                                <Typography variant="caption" display="block" key={status.key}><FormattedMessage id={status.key} />: <FormattedMessage id={'SAMPLE_LOG.STATUS.TSI.' + statusCodesConf.find(el => el.key === status.value).value} /></Typography>
                            ) : null}

                        </Box>)}
                </Grid>
                : null}

            {!!notes && notes.length ?
                <Box>
                    <Typography color="primary" variant='body2' sx={{ display: 'inline-block' }} ><FormattedMessage id='NOTES' /></Typography>
                    <Typography variant='body1' sx={{ display: 'inline', p: 1 }} >{notes}</Typography>
                </Box>
                : null}

            {pictures.length ?
                <Box>
                    <Typography color="primary" variant='body2' sx={{ my: 1 }} ><FormattedMessage id='SAMPLE_PICTURES' /></Typography>
                    <PictureGallery pictures={pictures} />
                </Box> : null
            }
        </Box>
    );
}