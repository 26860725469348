import React from "react";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { userSelector } from 'redux/userSlice';
import { AlarmDetails, AlarmButtons } from "components";
import { Card, CardContent, CardHeader, Container, CardActions, Tooltip, IconButton, Stack } from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';


function AlarmReportCard(props) {
    const {
        alarm, updateAlarmsData, mini
    } = props;
    const user = useSelector(userSelector);
    const navigate = useNavigate();

    const AlarmButtonsCustom = (props) => {
        return <AlarmButtons
            alarmId={alarm._id}
            locationId={alarm.locationId}
            user={user}
            ack={alarm.acknowledgedBy ? true : false}
            clear={alarm.clearedBy ? true : false}
            alarmsData={alarm}
            updateAlarmsData={updateAlarmsData}
            extraLinks={props.extraLinks}
        />
    }

    return mini ?
        <Stack direction="column" spacing={2}>
            <AlarmDetails mini alarm={alarm} />
            <AlarmButtonsCustom extraLinks />
        </Stack>
        : <Card>
            <Container sx={{ pb: 2 }}>
                <CardHeader title={'Alarm report'}
                    action={
                        <Tooltip title={<FormattedMessage id={"LOCATION.OPEN"} />} key="details" position="left" arrow>
                            <IconButton onClick={() => navigate('/locations/details/' + alarm.locationId)}>
                                <OpenInNewOutlined name="info" fontSize="small" />
                            </IconButton>
                        </Tooltip>}
                />
                <CardContent>
                    <AlarmDetails alarm={alarm} />
                </CardContent>
                <CardActions >
                    <AlarmButtonsCustom />
                </CardActions>
            </Container>
        </Card>;
}

export default AlarmReportCard;