import { Alert, Snackbar, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";



export default function AlertSnackbar(props) {
    const { open, onClose, autoHide, severity, messageId } = props;
    const autoHideDuration = Number(autoHide);
    return <>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={onClose}
            key={"alert-message"}
            autoHideDuration={autoHideDuration || 5000}
        >
            <Alert severity={severity} sx={{ width: '100%' }} onClose={onClose}>
                <Typography>
                    <FormattedMessage id={messageId} />
                </Typography>
            </Alert>
        </Snackbar>
    </>
}