import React from 'react';
import { Box, Card, Container, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { PictureGallery } from 'components';
import { useSelector } from 'react-redux';
import { selectResourceTypeGroupsConf } from 'redux/configurationSlice';

function SamplingLogDetails(props) {
    const { timestamp, locationName, locationId, logId, sampleType, values, notes, pictures, collector, staging, reviewedBy } = props;
    const resTypeGroups = useSelector(selectResourceTypeGroupsConf);

    const renderMeasurements = (row, name) => {
        return !!row.length ? <Stack key={name} direction="column">
            <Typography color='primary.main' variant='body2'><FormattedMessage id={'MEASUREMENTS.' + name} /> ({row.length})</Typography>
            <Stack direction="column" spacing={1}>
                {row.map(el =>
                    <Card key={el.id} sx={{ p: 1 }} >
                        <Typography variant='subtitle2'>{el.resource}</Typography>
                        <Box sx={{ pt: 1, display: 'inline-block' }}>
                            <Typography color='primary.main' variant='h6' display='inline' >{el.value}</Typography>
                            <Typography variant='body2' display='inline'>{el.unit}</Typography>
                        </Box>
                    </Card>
                )}
            </Stack>
        </Stack> : null;
    };

    const CustomText = (props) => {
        return <Stack direction='column'>
            <Typography variant='body2' color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
            {props.component ? props.component : <Typography variant='body1'>{props.text}</Typography>}
        </Stack>
    };

    const measurementTypes = resTypeGroups.map((group) => ({
        name: group.name,
        values: values.filter(value => group.resourceTypes.includes(value.id))
    }));

    return <Container disableGutters>
        <Stack direction='column' spacing={1}>
            <CustomText messageId="TIMESTAMP" text={timestamp} />
            <CustomText messageId="SAMPLING_LOG_REPORT.LOCATION_NAME" text={locationName} />
            <CustomText messageId="SAMPLING_LOG_REPORT.SAMPLED_OBJECT_ID" text={locationId} />
            <CustomText messageId="SAMPLING_LOG_REPORT.SAMPLING_LOG_ID" text={logId} />
            <CustomText messageId="SAMPLING_LOG_REPORT.SAMPLE_TYPE" text={<FormattedMessage id={'SAMPLE_LOG.TYPE_' + sampleType} />} />
            <CustomText messageId="SAMPLE_LOG.COLLECTOR" text={collector} />
            {staging && reviewedBy ? <CustomText messageId="REVIEWED_BY" text={reviewedBy.username} /> : null}
            {!!notes && notes.length ? <CustomText messageId="NOTES" component={
                <Card variant='outlined' sx={{ p: 0.5, fontStyle: 'italic' }}>
                    <Typography variant='body1'>{notes}</Typography>
                </Card>
            } /> : null}

            <Stack direction="column" spacing={2} sx={{ pt: 1 }}>
                {measurementTypes.map(type => renderMeasurements(type.values, type.name))}
            </Stack>

        </Stack>
        {!!pictures && !!pictures.length && <>
            <Typography color='primary.main' variant='body2'><FormattedMessage id='SAMPLE_PICTURES' /> ({pictures.length})</Typography>
            <PictureGallery maxWidth='300px' pictures={pictures.map(el => ({ name: el.split('/').slice(-1), url: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + el }))} />
        </>}
    </Container>;
}

export default SamplingLogDetails;