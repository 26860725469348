import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectSamplingTypesConf } from "redux/configurationSlice";

import { Autocomplete, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';


function AdditionalFilters(props) {
    const {
        filterHandler,
        sampleTypesSelected,
        // qualityTypesSelected,
        pictureSample,
    } = props;
    const intl = useIntl();

    const samplingTypesConfig = useSelector(selectSamplingTypesConf);
    // const statusCodesConfig = useSelector(selectStatusCodesConf).TSI;

    return <Grid container direction={'column'} spacing={2}>
        <Grid item>
            <Autocomplete
                multiple
                size="small"
                limitTags={3}
                disableCloseOnSelect
                id="multiple-limit-tags"
                options={samplingTypesConfig}
                filterSelectedOptions
                value={sampleTypesSelected}
                getOptionLabel={(sample) => intl.formatMessage({ id: `SAMPLE_LOG.TYPE_${sample.value}` })}
                onChange={(e, samplingType) => filterHandler(samplingType.filter(mapedSamplingType => samplingTypesConfig.map(filteredSamplingType => filteredSamplingType === mapedSamplingType)), 'samples')}
                renderInput={(params) => (
                    <TextField {...params} label={intl.formatMessage({ id: "SAMPLE_LOG.TYPES" })} />
                )}
                fullWidth
            />

        </Grid>
        {/* <Grid item>
            <Autocomplete
                multiple
                size="small"
                limitTags={3}
                disableCloseOnSelect
                id="multiple-limit-tags"
                options={statusCodesConfig}
                filterSelectedOptions
                value={qualityTypesSelected}
                getOptionLabel={(statusCodes) => intl.formatMessage({ id: `SAMPLE_LOG.STATUS.TSI.${statusCodes.value}` })}
                onChange={(e, statusCodes) => filterHandler(statusCodes.filter(mapedStatusCodes => statusCodesConfig.map(filteredStatusCodes => filteredStatusCodes === mapedStatusCodes)), 'quality')}
                renderInput={(params) => (
                    <TextField {...params} label={intl.formatMessage({ id: "QUALITY_TYPE" })} />
                )}
                fullWidth
            />
        </Grid> */}
        <Grid item>
            <FormControlLabel
                control={<Checkbox checked={pictureSample} onChange={() => filterHandler(!pictureSample, 'picture')} />}
                label={<Typography variant="subtitle1" ><FormattedMessage id="CONTAINS_PICTURE" /></Typography>}
                sx={{ display: 'inline-flex !important' }}
            />
        </Grid>
    </Grid>;
}

export default AdditionalFilters;