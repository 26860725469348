import instance, { setAuthHeader } from './axiosEnv';

const getAllConfiguration = () => {
    return instance.get('/configuration');
}

const getAllConfigurationData = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/configuration/all', config);
}

const authAPI = {
    getAllConfiguration,
    getAllConfigurationData,
}

export default authAPI;