import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { permissionSelector } from "redux/userSlice";
import { Button, ButtonGroup, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { ManageSearchOutlined, OpenInNewOutlined, CheckOutlined, ClearOutlined } from "@mui/icons-material";
import { AlertSnackbar } from 'components';
import API from "api";


function AlarmButtons(props) {
    const { alarmId, locationId, user, ack, clear, updateAlarmsData, extraLinks } = props;
    const [ackClick, setAck] = useState(false);
    const [clrClick, setClr] = useState(false);
    const alarmAckRights = useSelector((state) => permissionSelector(state, 'acknowledge-alarms'));
    const alarmClrRights = useSelector((state) => permissionSelector(state, 'clear-alarms'));
    const navigate = useNavigate();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const onSuccess = (data) => {
        if (data) updateAlarmsData(data);
        if (ackClick) setAck(false);
        else if (clrClick) setClr(false);
    }

    const CustomButton = (props) => {
        return !smallScreen ? <Button variant='text' disabled={props.disabled} onClick={props.onClick}>
            <FormattedMessage id={props.messageId} />
        </Button> : <Tooltip title={<FormattedMessage id={props.messageId} />} arrow >
            <span>
                <IconButton color={"primary"} disabled={props.disabled} onClick={props.onClick}>
                    {props.children}
                </IconButton>
            </span>
        </Tooltip>
    }

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <ButtonGroup>
            <CustomButton messageId='ACKNOWLEDGE' disabled={!(alarmAckRights && alarmAckRights.includes(locationId)) || (ack || ackClick)} onClick={() => {
                setAck(true);
                API.alarms.acknowledgeAlarm(user.token, alarmId)
                    .then(({ data }) => {
                        onSuccess(data);
                    }).catch((error) => {
                        setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                        console.error("Error with alarm: ", { error: error, alarmId });
                        onSuccess(null);
                    });
            }}>
                <CheckOutlined fontSize="small" />
            </CustomButton>
            <CustomButton messageId='CLEAR' disabled={!(alarmClrRights && alarmClrRights.includes(locationId)) || (clear || clrClick)} onClick={() => {
                setClr(true);
                API.alarms.clearAlarm(user.token, alarmId)
                    .then(({ data }) => {
                        onSuccess(data);
                    }).catch((error) => {
                        setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                        console.error("Error with alarm: ", { error: error, alarmId });
                        onSuccess(null);
                    });
            }}>
                <ClearOutlined fontSize="small" />
            </CustomButton>

            {!!extraLinks && <div style={{ marginLeft: 'auto' }}>
                <Tooltip title={<FormattedMessage id='ALARM.DETAILS' />} arrow>
                    <IconButton onClick={() => navigate('/alarms/details/' + alarmId)}><ManageSearchOutlined fontSize="small" /></IconButton>
                </Tooltip>
                <Tooltip title={<FormattedMessage id='VIEW_LOCATION' />} arrow>
                    <IconButton onClick={() => navigate('/locations/details/' + locationId)} ><OpenInNewOutlined fontSize="small" /></IconButton>
                </Tooltip>
            </div>}
        </ButtonGroup>
    </>
}

export default AlarmButtons;