import { Typography } from '@mui/material';



export default function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ py: 0.5 }}>
            
            {process.env.REACT_APP_TITLE}
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};