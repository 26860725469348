import React from 'react';
import MenuItems from './MenuItems';
import { Toolbar, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomMenuDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(40),
            },
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                [theme.breakpoints.down('sm')]: {
                    width: 0,
                },
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(8),
                },
            }),
        },
    }),
);

export default function MenuDrawer(props) {
    const { open, selectedItem, admin } = props;

    return (
        <CustomMenuDrawer variant="permanent" open={open}>
            <Toolbar />
            <MenuItems open={open} selectedItem={selectedItem} secondaryItems={admin} />
        </CustomMenuDrawer>
    );
};