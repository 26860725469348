import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";
import { FormattedMessage } from "react-intl";
import { AlarmProfileTableContainer, AlarmProfileFilters, AlarmProfileForm } from "components";
import { Dialog, Stack, Card, CardHeader, CardContent, Tooltip, IconButton, useMediaQuery } from '@mui/material';
import { NotificationAddOutlined } from '@mui/icons-material';


export const AddAlarmProfileDialog = (props) => {
    const createRights = useSelector((state) => permissionSelector(state, 'create-alarmProfiles'));
    const fullScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const { openDialog, setOpenDialog, templateFlag, alarm } = props;

    return <>
        <Dialog
            open={openDialog}
            fullScreen={fullScreen}
            fullWidth
            maxWidth='md'
            aria-labelledby="location-details-form-title"
            scroll="paper"
        >
            <CardHeader title={<FormattedMessage id="ALARM.PROFILE.FORM" />} />
            <CardContent>
                <AlarmProfileForm onSubmit={() => setOpenDialog(false)} onClose={() => setOpenDialog(false)} alarm={alarm} templateFlag />
            </CardContent>
        </Dialog>
        {!templateFlag && <CardHeader title={<FormattedMessage id="ALARM.PROFILES.FILTERS" />}
            action={
                <Tooltip placement="left" title={<FormattedMessage id="ALARM.PROFILES.ADD" />} arrow>
                    <span>
                        <IconButton disabled={!createRights} color="primary" onClick={() => setOpenDialog(true)}><NotificationAddOutlined /></IconButton>
                    </span>
                </Tooltip>
            } />}
    </>

}

export default function AlarmsProfileView() {
    const [locations, setLocations] = useState([]);
    const [selectEnabled, setSelectEnabled] = useState(undefined);
    const [nameIncludes, setNameIncludes] = useState(undefined);
    const [selectInactive, setSelectInactive] = useState(false);
    const [selectedNotificationTypes, setSelectedNotificationTypes] = useState(undefined);
    const [severityTypesSelected, setSeverityTypesSelected] = useState(undefined);
    const [selectTriggered, setSelectTriggered] = useState(false);
    const [displayAll, setDisplayAll] = useState(true)
    const [openDialog, setOpenDialog] = useState(false);

    const filterHandler = useCallback((data, type) => {
        switch (type) {
            case 'nameIncludes':
                if (data === "") setNameIncludes(undefined);
                else setNameIncludes(data);
                return;
            case 'locations':
                setLocations(data);
                return;
            case 'enabled':
                setSelectEnabled(data);
                return;
            case 'inactive':
                setSelectInactive(!selectInactive);
                return;
            case 'triggered':
                if (data === null) setSelectTriggered(!selectTriggered);
                else setSelectTriggered(data);
                return;
            case 'severityTypes':
                setSeverityTypesSelected(data);
                return;
            case 'notificationTypes':
                setSelectedNotificationTypes(data);
                return;
            case 'displayAll':
                if (data === null) setDisplayAll(!displayAll);
                else setDisplayAll(data);
                return;
            default:
                setLocations([]);
                setNameIncludes(undefined);
                setSeverityTypesSelected(undefined);
                setSelectedNotificationTypes(undefined);
                setSelectEnabled(false);
                setSelectTriggered(false);
                setSelectInactive(false);
                return;
        }
    }, [selectInactive, selectTriggered, displayAll]);

    return <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
        <Card>
            <AddAlarmProfileDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
            <CardContent>
                <AlarmProfileFilters
                    filterHandler={filterHandler}
                    nameSelected={nameIncludes}
                    selectedLocations={locations}
                    selectEnabled={selectEnabled}
                    selectedNotificationTypes={selectedNotificationTypes}
                    selectInactive={selectInactive}
                    selectTriggered={selectTriggered}
                    severityTypesSelected={severityTypesSelected}
                    displayAll={displayAll}
                />
            </CardContent>
        </Card>
        <AlarmProfileTableContainer
            locations={locations.map(l => l._id)}
            nameIncludes={nameIncludes}
            severityTypesSelected={severityTypesSelected}
            selectedNotificationTypes={selectedNotificationTypes}
            selectEnabled={selectEnabled}
            selectInactive={selectInactive}
            selectTriggered={selectTriggered}
            displayAll={displayAll}
        />
    </Stack>;

}