import instance, { setAuthHeader } from './axiosEnv';
import { localISOString } from 'utils';

const getMeasurementsHistory = (token, locationId = '', resourceTypes = '', dateFrom = null, dateTo = null, sampleTypes = '', measurePointTypes='', page, size) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }

    for (let el in resourceTypes) {
        queryParams.append('resourceType', resourceTypes[el]);
    }

    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el]);
    }

    for (let el in measurePointTypes) {
        queryParams.append('measurePointType', measurePointTypes[el]);
    }

    queryParams.append('page', page);
    queryParams.append('size', size);

    return instance.get('/measurements?' + queryParams.toString(), config);
}

const getMultiTypeMeasurements = (token, locationId, resourceTypes = '', dateFrom = null, dateTo = null, sampleTypes = '', aggregation = null, measurePointTypes) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('locationId', locationId);
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));

    for (let el in resourceTypes) {
        queryParams.append('resourceType', resourceTypes[el]);
    }

    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el]);
    }

    for (let el in measurePointTypes) {
        queryParams.append('measurePointType', measurePointTypes[el]);
    }

    queryParams.append('aggregation', aggregation);
    const root = token === null ? '/measurements/public/values?' : '/measurements/values?';
    return instance.get(root + queryParams.toString(), config);
}

const getLocationLatest = (token, locationId = '', resourceTypes = '', date = new Date()) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('date', localISOString(date));

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }

    for (let el in resourceTypes) {
        queryParams.append('resourceType', resourceTypes[el]);
    }

    return instance.get('/measurements/latest?' + queryParams.toString(), config);
}


const measurementsAPI = {
    getMultiTypeMeasurements,
    getLocationLatest,
    getMeasurementsHistory
}

export default measurementsAPI;
