import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { DateRangeSelector } from 'components';
import { useSelector } from 'react-redux';
import { selectMeasurePoints } from 'redux/locationsSlice';

import { FormControlLabel, Checkbox, Autocomplete, Card, CardHeader, CardContent, Stack, Grid, TextField, Typography, useMediaQuery } from '@mui/material';

function StagingSampleReportingFilters(props) {
    const {
        selectedLocations,
        filterHandler,
        pictureSample,
    } = props;
    const intl = useIntl();
    const locations = useSelector(selectMeasurePoints);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    return <Card>
        <CardHeader title={<FormattedMessage id="STAGING_SAMPLES" />} />
        <CardContent>
            <Stack direction="column" spacing={2}>
                <DateRangeSelector column={smallScreen} />
                <Grid container direction="row">
                    <Grid item xl={4} lg={5} md={6} sm={12} xs={12}>
                        <Autocomplete
                            multiple
                            size="small"
                            limitTags={3}
                            disableCloseOnSelect
                            id="multiple-limit-tags"
                            options={locations}
                            filterSelectedOptions
                            getOptionLabel={(location) => location.name}
                            value={selectedLocations}
                            onChange={(e, location) => filterHandler(location.filter(mapedLocation => locations.map(filteredLocation => filteredLocation === mapedLocation)), 'locations')}
                            renderInput={(params) => (
                                <TextField {...params} label={intl.formatMessage({ id: "ALL_LOCATIONS" })} />
                            )}
                            fullWidth

                        />
                    </Grid>
                    <Grid item xl={4} lg={5} md={6} sm={12} xs={12}>
                        <FormControlLabel
                            sx={smallScreen ? { mt: 1 } : { ml: 1 }}
                            control={<Checkbox checked={pictureSample} onChange={() => filterHandler(!pictureSample, 'picture')} />}
                            label={<Typography variant="subtitle1" ><FormattedMessage id="CONTAINS_PICTURE" /></Typography>}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </CardContent>
    </Card >;
}

export default StagingSampleReportingFilters;