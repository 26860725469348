import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { selectLocationDetails } from 'redux/locationsSlice'
import { userSelector } from "redux/userSlice";
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { NotesView, LoginRequired } from "components";


function NotesPage(props) {
    const [searchparams] = useSearchParams();
    const locationId = searchparams.get("locationId");
    const location = useSelector((state) => selectLocationDetails(state, locationId));

    if (!locationId) return <EmptyState component={<NotesView />} />;
    else if (locationId) return <EmptyState component={<NotesView locationId={location._id} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function NotesPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<NotesPage />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};