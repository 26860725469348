import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { fullNameSelector } from 'redux/userSlice';
import { selectPermissionsByKey, selectStagingSamplesFeature } from 'redux/configurationSlice';
import { Box, Button, Tooltip, useMediaQuery } from '@mui/material';
import { ReviewsOutlined, DeleteOutlined, CheckCircleOutlined } from '@mui/icons-material';
import API from 'api';

export default function StagingSamplesButtons(props) {
    const { sample, token, sampleData, setReviewerData, setApproverData } = props
    const username = useSelector((state) => fullNameSelector(state))
    const [disabledFlag, setDisabledFlag] = useState(false)
    const locationId = sample.locationId
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const reviewed = sample.reviewedBy ? (sample.reviewedBy.username !== "" ? true : false) : false
    const permissionList = useSelector(selectPermissionsByKey)
    const stagingFeature = useSelector((state) => selectStagingSamplesFeature(state));
    const approveSample = permissionList['approve-staging-sample'] ? permissionList['approve-staging-sample'].includes(locationId) : false
    const reviewSample = permissionList['review-staging-sample'] ? permissionList['review-staging-sample'].includes(locationId) : false
    const rejectSample = permissionList['reject-staging-sample'] ? permissionList['reject-staging-sample'].includes(locationId) : false

    const handleApproveSample = () => {
        const newArray = [...sampleData]
        const sampleIndex = newArray.indexOf(sample)
        setDisabledFlag(true)

        API.samplingLogs.approveStagingSample(token, sample.id)
            .then((item) => {
                newArray.splice(sampleIndex, 1)
                setApproverData(newArray)
                setDisabledFlag(false)
            })
            .catch((error) => {
                console.error("Error while approving sample: ", { error: error })
                setDisabledFlag(false)
            });
    }

    const handleReviewSample = () => {
        const newArray = [...sampleData]
        const sampleIndex = newArray.indexOf(sample)
        setDisabledFlag(true)

        API.samplingLogs.reviewStagingSample(token, sample.id)
            .then(() => {
                const reviewedSample = {
                    ...sample,
                    reviewedBy: {
                        username: username
                    }
                }
                newArray.splice(sampleIndex, 1, reviewedSample)
                setReviewerData(newArray)
                setDisabledFlag(false)
            })
            .catch((error) => {
                console.error("Error while reviewing sample: ", { error: error })
                setDisabledFlag(false)
            });
    }

    const handleRejectSample = () => {
        const newArray = [...sampleData]
        const sampleIndex = newArray.indexOf(sample)
        setDisabledFlag(true)

        API.samplingLogs.rejectStagingSample(token, sample.id)
            .then(() => {
                newArray.splice(sampleIndex, 1)
                if (approveSample) setApproverData(newArray)
                else if (reviewSample) setReviewerData(newArray)
                setDisabledFlag(false)
            })
            .catch((error) => {
                console.error("Error while rejecting sample: ", { error: error });
                setDisabledFlag(false)
            });
    }

    return <>
        {approveSample && stagingFeature && <Box sx={{ mr: 3 }}>

            <Button disabled={disabledFlag} onClick={() => handleApproveSample()}>{!mediumScreen ? <FormattedMessage id='APPROVE' /> :
                <Tooltip title={<FormattedMessage id='APPROVE' />} arrow>
                    <CheckCircleOutlined color="success" />
                </Tooltip>}
            </Button>

            {rejectSample && <Button disabled={disabledFlag} onClick={() => handleRejectSample()} color="warning">{!mediumScreen ? <FormattedMessage id='REJECT' /> :
                <Tooltip title={<FormattedMessage id='REJECT' />} arrow>
                    <DeleteOutlined color="warning" />
                </Tooltip>}
            </Button>}
        </Box>
        }

        {
            reviewSample && stagingFeature && !reviewed && <Box sx={{ mr: 2 }}>

                <Button disabled={disabledFlag || reviewed} onClick={() => handleReviewSample()} >{!mediumScreen ? <FormattedMessage id='ACCEPT' /> :
                    <Tooltip title={<FormattedMessage id='ACCEPT' />} arrow>
                        <ReviewsOutlined />
                    </Tooltip>}
                </Button>

                {rejectSample && <Button disabled={disabledFlag || reviewed} onClick={() => handleRejectSample()} color="warning">{!mediumScreen ? <FormattedMessage id='REJECT' /> :
                    <Tooltip title={<FormattedMessage id='REJECT' />} arrow>
                        <DeleteOutlined />
                    </Tooltip>}
                </Button>}
            </Box>
        }
    </>
}