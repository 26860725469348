import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { selectMeasurePoints } from 'redux/locationsSlice';
import { AlarmButtons, CustomDataGrid, AlarmReportCard, SideDrawer } from "components";
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { LabelImportantOutlined } from '@mui/icons-material';
import { formatDateLocale } from 'utils';


export default function AlarmHistoryTable(props) {
  const { alarmsData, updateAlarmsData } = props;
  const intl = useIntl();
  const user = useSelector(userSelector);
  const locations = useSelector(selectMeasurePoints);
  const alarmAckRights = useSelector((state) => permissionSelector(state, 'acknowledge-alarms'));
  const alarmClrRights = useSelector((state) => permissionSelector(state, 'clear-alarms'));
  const [details, setDetails] = useState({});
  const [detailsAlarm, setDetailsAlarm] = useState({});
  const [openDrawer, setDrawer] = useState(false);
  const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const theme = useTheme()

  const renderTable = useCallback(() => {
    const headers = [
      {
        field: 'name',
        headerName: intl.formatMessage({ id: 'NAME' }),
        ...(!smallScreen && { flex: 1 }),
        minWidth: 150
      },
      {
        field: 'location',
        headerName: intl.formatMessage({ id: 'LOCATION' }),
        ...(!smallScreen && { flex: 2 }),
        minWidth: 150
      },
      {
        field: 'triggeredTime',
        headerName: intl.formatMessage({ id: 'TRIGGERED_TIME' }),
        ...(!smallScreen && { flex: 1 }),
        minWidth: 150
      },
      {
        field: 'severity',
        headerName: intl.formatMessage({ id: 'SEVERITY' }),
        renderCell: ({ value }) => <span>
          <LabelImportantOutlined
            name={'severity-' + value}
            className={'severity-' + value}
            sx={{ width: theme.typography.fontSize, height: theme.typography.fontSize, pt: 0.5 }}
          />
          <FormattedMessage id={'SEVERITY.' + value} />
        </span>,
        ...(!smallScreen && { flex: 0 }),
        minWidth: 150
      }
    ];

    if (alarmAckRights || alarmClrRights) headers.push({
      field: 'actions',
      headerName: intl.formatMessage({ id: 'ALARM.ACTIONS' }),
      type: 'actions',
      getActions: (el) => [
        <AlarmButtons
          key={el.id}
          alarmId={el.row._id}
          locationId={el.row.locationId}
          user={user}
          ack={el.row.acknowledgedBy ? true : false}
          clear={el.row.clearedBy ? true : false}
          alarmsData={alarmsData}
          updateAlarmsData={updateAlarmsData} />
      ],
      ...(!smallScreen && { flex: 2 }),
      minWidth: 150
    });

    const handleData = ({ row }) => {
      setDetailsAlarm(row);
      setDrawer(true);
    }

    const getCellClassName = (params) => {
      if (params.field === '#' && params.value !== null) {
        return params.value;
      }
    }

    return <CustomDataGrid customCellClass={getCellClassName} rows={alarmsData.map(el => ({ ...el, triggeredTime: formatDateLocale(el.triggeredTime), location: locations.find(l => l._id === el.locationId).name }))} columns={headers} handleData={handleData} />;

  }, [intl, alarmsData, locations, user, updateAlarmsData, alarmAckRights, alarmClrRights, smallScreen, theme.typography.fontSize]);

  useEffect(() => {
    if (detailsAlarm) {
      const alarm = alarmsData.find(alarm => alarm._id === detailsAlarm._id);
      if (alarm && (alarm.acknowledgedBy !== detailsAlarm.acknowledgedBy || alarm.clearedBy !== detailsAlarm.clearedBy)) {
        setDetailsAlarm(alarm);
      }

      try {
        setDetails({
          title: <FormattedMessage id='ALARM.DETAILS' />,
          subtitle: formatDateLocale(alarm.triggeredTime),
          display: <AlarmReportCard mini alarm={alarm} updateAlarmsData={updateAlarmsData} />
        })
      } catch (err) {
        setDetails({
          title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
          subtitle: '',
          display: <div />
        });
      }
    }
  }, [alarmsData, detailsAlarm, updateAlarmsData])

  return <Box>
    {renderTable()}
    <SideDrawer state={details} open={openDrawer} toggleDrawer={() => setDrawer(false)} />
  </Box>
}