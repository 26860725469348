import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { permissionSelector } from "redux/userSlice";
import { selectGroupsMeasurePoints, selectLocations } from 'redux/locationsSlice';
import { LocationMeasurePointCard, LocationDetailsDialogForm } from "components";
import { Grid, Card, Tooltip, IconButton } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";

export default function LocationGroupsCardView(props) {
    const { locationGroupId } = props;
    const groupAdmin = useSelector((state) => permissionSelector(state, 'create-location'));
    const locationGroup = useSelector((state) => selectLocations(state, locationGroupId))[0];
    const locations = useSelector((state) => selectGroupsMeasurePoints(state, locationGroup._id));
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    const handleClickOpenForm = () => {
        setOpenFormDialog(true);
        setSuccessMsg("");
    };

    const handleCloseForm = () => {
        setOpenFormDialog(false);
    };

    return (
        <Grid container spacing={2} >
            {
                locations.map((location, i) => (
                    <Grid key={i} item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex' }}>
                        <LocationMeasurePointCard location={location} />
                    </Grid>
                ))
            }
            {groupAdmin && groupAdmin.includes(locationGroup._id) ? <Grid key="addLocationIcon" item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex' }}>
                <Card sx={{ width: '100%', minHeight: '240px', display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title={<FormattedMessage id="ADD_LOCATION" />} arrow placement="bottom">
                        <IconButton color="primary" sx={{ m: 'auto' }} key="addLocationGroup" onClick={handleClickOpenForm}><AddOutlined fontSize="large" /></IconButton>
                    </Tooltip>
                </Card>
            </Grid> : null}
            <LocationDetailsDialogForm
                openFormDialog={openFormDialog}
                handleCloseForm={handleCloseForm}
                successMsg={successMsg}
                setSuccessMsg={setSuccessMsg}
                locationGroupId={locationGroup._id}
            />
        </Grid>
    );
}