import { fromLonLat, transform } from "ol/proj";
import { Vector as VectorLayer } from 'ol/layer';
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { useTheme } from '@mui/material';
import { MARKERS } from 'components';

function MapElementNew(props) {
    const { map, markerSource, positionChange, positionInput } = props;
    const theme = useTheme();

    new VectorLayer({
        source: markerSource,
        map: map
    });
    const markerStyle = MARKERS.locationNew(theme.palette.mode);

    const renderMarker = () => {
        // clear current feature
        markerSource.clear();
        // create new feature on Map
        const newPin = new Feature({
            geometry: new Point(fromLonLat(positionInput))
        });
        // apply styles
        newPin.setStyle(markerStyle);
        // add feature to source
        markerSource.addFeature(newPin);
        map.getView().setCenter(fromLonLat(positionInput));
    }

    map.on('click', (evt) => {
        const feature = map.forEachFeatureAtPixel(evt.pixel, (feature) => {
            return feature;
        });

        if (!feature) {
            // get coordinates from map click
            const pointCoordinates = transform(
                map.getCoordinateFromPixel(evt.pixel),
                "EPSG:3857",
                "EPSG:4326"
            );

            return positionChange(pointCoordinates);
        }
    })

    return renderMarker();
}

export default MapElementNew;