import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Card, CardContent, CardHeader } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { selectSamplingTypesConf } from 'redux/configurationSlice';
import { DoughnutChart } from 'charts'
import { LoadingData, AlertSnackbar } from 'components';

import API from 'api';

const SamplingLogTypeDistributionPie = (props) => {
  const { locations, sampleTypes, qualityTypes, collectors, pictureSample } = props;
  const { token } = useSelector(userSelector);
  const selectedDateRange = useSelector(selectDateRange);
  const samplingType = useSelector(selectSamplingTypesConf);
  const intl = useIntl();
  const [data, setData] = useState(null);
  const [alert, setAlert] = useState({ open: false });

  const onAlertClose = () => setAlert({ ...alert, open: false });

  const convertData = useCallback((apiData) => {
    const chartData = {
      labels: [],
      datasets: [{ data: [], keys: [] }]
    }
    apiData.forEach(row => {
      const type = `SAMPLE_LOG.TYPE_${samplingType.find(el => el.key === row._id).value}`;
      chartData.labels.push(intl.formatMessage({ id: type }));
      chartData.datasets[0].keys.push(row._id);
      chartData.datasets[0].data.push(row.count);
    });
    setData({ ...chartData });
  }, [setData, intl, samplingType]);

  useEffect(() => {
    API.samplingLogs.getSampleTypeDistribution(token, locations, selectedDateRange.dateFrom, selectedDateRange.dateTo, qualityTypes, sampleTypes, collectors, pictureSample)
      .then(items => {
        if (items.data && items.data.length) {
          convertData(items.data);
        }
        else setData({});
      }).catch(error => {
        setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
        setData({ error: error });
      });
  }, [token, locations, selectedDateRange, sampleTypes, qualityTypes, collectors, pictureSample, convertData])

  const render = useCallback(() => {
    if (data !== null && data.datasets) return <DoughnutChart name={"typeDoughnutChart_" + locations[0]} data={data} />;
    else if (data === null) return <LoadingData noText />;
    else if (data.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
    else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>
  }, [locations, data]);

  return <>
    <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
    <Card sx={{ width: '100%' }}>
      <CardHeader title={<FormattedMessage id="SAMPLING_LOG_REPORT.SAMPLE_TYPE" />} />
      <CardContent sx={{ height: '100%' }}>
        {render()}
      </CardContent>
    </Card>
  </>
}

export default SamplingLogTypeDistributionPie;