import React from "react";
import { FormattedMessage } from 'react-intl';
import { Stack, Typography, Card, CardContent, Box, Grid, useTheme } from '@mui/material';
import { useSelector } from "react-redux";
import { DisplayResourceName } from "components";
import { selectLocationDetails } from "redux/locationsSlice";
import { selectResourcesTypesConf } from 'redux/configurationSlice';
import { LabelImportantOutlined } from "@mui/icons-material";
import { formatDateLocale } from "utils";


function AlarmDetails(props) {
    const { alarm, mini } = props;
    const location = useSelector((state) => selectLocationDetails(state, alarm.locationId));
    const resourceTypes = useSelector(selectResourcesTypesConf);
    const theme = useTheme();

    const CustomText = (props) => {
        return <Stack direction='column'>
            <Typography variant='body2' color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
            {props.children ? props.children : <Typography variant='body1'>{props.text}</Typography>}
        </Stack>
    };

    const CustomCard = (props) => {
        return <div>
            <Typography variant='body2' color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
            <Card sx={{ p: 1 }}>
                <Typography variant='body1'>{props.text}</Typography>
                <Typography variant='caption' color='text.secondary'>{props.caption}</Typography>
            </Card>
        </div>
    }

    const renderMeasurements = (row) => {
        let conditions = row ? row.map((element) => {
            let resource = resourceTypes.find((el) => el.key === parseInt(element.resourceTypeKey));
            return {
                value: element.value,
                unit: resource.unit,
                resourceType: resource
            }
        }) : []

        const sizeProps = {
            xs: 12,
            sm: mini ? 12 : 6,
            md: mini ? 12 : 4,
            lg: mini ? 12 : 3,
            xl: mini ? 12 : 2
        }

        return !!conditions.length ? <Stack direction="column">
            <Typography color='primary.main' variant='body2'><FormattedMessage id={"CONDITIONS_PASSED"} /> ({conditions.length})</Typography>
            <Grid container spacing={1}>
                {conditions.map((el, i) =>
                    <Grid item key={i} {...sizeProps}>
                        <Card>
                            <CardContent>
                                <Typography variant='subtitle2'><DisplayResourceName resource={el.resourceType} /></Typography>
                                <Box sx={{ pt: 1, display: 'inline-block' }}>
                                    <Typography color='primary.main' variant='h6' display='inline' >{el.value}</Typography>
                                    <Typography variant='body2' display='inline'>{el.unit}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Stack> : null;
    };

    return <Stack spacing={1} direction={'column'} sx={mini ? { px: 1 } : null}>

        <CustomText messageId="NAME" text={alarm.name} />
        <CustomText messageId="TRIGGERED_TIME" text={formatDateLocale(alarm.triggeredTime)} />
        <CustomText messageId="SEVERITY">
            <Stack direction="row">
                <LabelImportantOutlined
                    name={'severity-' + alarm.severity}
                    className={'severity-' + alarm.severity}
                    key={alarm.triggeredTime}
                    sx={{ width: theme.typography.fontSize * 1.5, height: theme.typography.fontSize * 1.5, pt: 0.5 }}
                />
                <Typography variant='body1' >
                    <FormattedMessage id={"SEVERITY." + alarm.severity} />
                </Typography>
            </Stack>
        </CustomText>
        <CustomText messageId="LOCATION" text={location.name} />
        <CustomText messageId="SAMPLE_LOG.COLLECTOR" text={alarm.collector} />
        <CustomText messageId="MESSAGE">
            <Card variant='outlined' sx={{ p: mini ? 0.5 : 1, fontStyle: 'italic' }}>
                <Typography variant='body1'>{alarm.message}</Typography>
            </Card>
        </CustomText>

        {
            alarm.acknowledgedBy ?
                <CustomCard messageId="ACKNOWLEDGED" text={alarm.acknowledgedBy.username} caption={formatDateLocale(alarm.acknowledgedTime)} />
                : null
        }
        {
            alarm.clearedBy ?
                <CustomCard messageId="CLEARED" text={alarm.clearedBy.username} caption={formatDateLocale(alarm.clearedTime)} />
                : null
        }
        <Stack direction="column" spacing={2} sx={{ pt: 1 }}>
            {renderMeasurements(alarm.conditionsPassed)}
        </Stack>
    </Stack >;
}

export default AlarmDetails;