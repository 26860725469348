import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Checkbox, Chip, FormControlLabel, Tooltip, Typography } from '@mui/material';
import { FileUploadOutlined, FileDownloadOutlined, InfoOutlined } from '@mui/icons-material';

export default function AddSampleHistoryForm(props) {
    const { selectedLocation, file, setFile, handleDownloadClick, handleUploadClick, skipErrors, changeSkipError } = props;
    const fileInputRef = useRef(null);

    const handleInputChange = event => {
        setFile(event.target.files[0]);
    }

    const handleInputClick = event => {
        event.target.value = null;
        setFile(null);
    }

    const handleClick = event => {
        fileInputRef.current.click();
    }

    return <>
        {
            selectedLocation ? <>
                <Button startIcon={<FileUploadOutlined />} onClick={handleClick}>
                    <FormattedMessage id="FILE_SELECT" />
                </Button>
                <input
                    name="history-table"
                    type="file"
                    accept='.xlsx, .csv'
                    ref={fileInputRef}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                    style={{ display: "none" }}
                />
                <Button startIcon={<FileDownloadOutlined />} onClick={handleDownloadClick}>
                    <FormattedMessage id="DOWNLOAD_TEMPLATE" />
                </Button>
            </>
                : null
        }

        {
            file ? <Box sx={{ mt: 2, display: 'flex-column', justifyContent: 'start' }}>
                <div>
                    <Chip sx={{ mr: 2 }} label={<Typography variant="caption">{file.name}</Typography>} onDelete={() => setFile(null)} />
                    <FormControlLabel
                        control={<Checkbox color="warning" size="small" checked={skipErrors} onChange={changeSkipError} />}
                        label={
                            <Typography variant="body2">
                                <FormattedMessage id="SKIP_ERRORS" />
                                <Tooltip title={<FormattedMessage id="SKIP_ERRORS_TOOLTIP" />} arrow>
                                    <InfoOutlined color="disabled" sx={{ width: '16px', height: '16px' }} />
                                </Tooltip>
                            </Typography>
                        }
                    />
                </div>
                <Button onClick={handleUploadClick} type="submit"><FormattedMessage id="SUBMIT" /></Button>
            </Box> : null
        }
    </>
}