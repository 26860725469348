import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { permissionSelector } from "redux/userSlice";
import { Button, ButtonGroup, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { ManageSearchOutlined, NotificationsActiveOutlined, NotificationsPausedOutlined, MenuOutlined, ContentCopyOutlined } from "@mui/icons-material";
import { AddAlarmProfileDialog, AlertSnackbar } from 'components';
import API from "api";


function AlarmButtons(props) {
    const { alarmProfileId, enabled, locationId, user, updateAlarmsData, detailsPage, alarm } = props;
    const updateRights = useSelector((state) => permissionSelector(state, 'update-alarmProfiles'));
    const navigate = useNavigate();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const [alert, setAlert] = useState({ open: false });
    const [openDialog, setOpenDialog] = useState(false);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const CustomButton = (props) => {
        return !smallScreen ? <Button variant='text' disabled={props.disabled} onClick={props.onClick}>
            <FormattedMessage id={props.messageId} />
        </Button> : <Tooltip title={<FormattedMessage id={props.messageId} />} arrow>
            <IconButton color={"primary"} disabled={props.disabled} onClick={props.onClick}>
                {props.children}
            </IconButton>
        </Tooltip>
    }
    return <>
        <AddAlarmProfileDialog openDialog={openDialog} setOpenDialog={setOpenDialog} templateFlag alarm={alarm} onClose />
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <ButtonGroup variant={!smallScreen ? "text" : "default"} size={smallScreen ? "small" : "medium"}>
            <CustomButton messageId={enabled ? 'DISABLE' : 'ENABLE'} disabled={!(updateRights && updateRights.includes(locationId))} onClick={() => {
                const apiCall = enabled ? API.alarmProfiles.disableAlarmProfile : API.alarmProfiles.enableAlarmProfile
                apiCall(user.token, alarmProfileId)
                    .then((res) => {
                        if (res && res.data) updateAlarmsData(res.data);
                    }).catch((error) => {
                        setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                        console.error("Error with alarm profile: ", { error: error, alarmProfileId });
                        updateAlarmsData(null);
                    });
            }}>
                {enabled ? <NotificationsPausedOutlined /> : <NotificationsActiveOutlined />}
            </CustomButton>
            {!detailsPage ?
                <Tooltip title={<FormattedMessage id='ALARM.PROFILE.DETAILS_CARD' />} arrow>
                    <IconButton color="primary" onClick={() => navigate('/alarms/profiles/' + alarmProfileId)}><ManageSearchOutlined fontSize="small" /></IconButton>
                </Tooltip>
                : <Tooltip title={<FormattedMessage id="ALARM.PROFILES.TABLE" />} arrow>
                    <IconButton color="primary" onClick={() => navigate('/alarms/profiles')}><MenuOutlined fontSize="small" /></IconButton>
                </Tooltip>
            }

            {props.children}
            <Tooltip title={<FormattedMessage id='ALARM.PROFILES.COPY' />} arrow >
                <span>
                    <IconButton disabled={!(updateRights && updateRights.includes(locationId))} color="primary" onClick={() => setOpenDialog(true)} ><ContentCopyOutlined fontSize="small" /></IconButton>
                </span>
            </Tooltip>
            <div style={{ marginLeft: 'auto' }}>

            </div>
        </ButtonGroup>
    </>
}

export default AlarmButtons;