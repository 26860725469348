import { useState, useCallback } from 'react';
import { Stack, Autocomplete, Box, Tooltip, TextField, MenuItem, useMediaQuery, IconButton, InputAdornment } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { DisplayResourceName } from "components";
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectResourcesTypesConf } from 'redux/configurationSlice';
import { selectLocationDetails } from 'redux/locationsSlice'



export default function AlarmProfileCondition(props) {
    const { condition, index, filtIndex, rule, updateAlarm, disabled, locationId } = props;
    const location = useSelector((state) => selectLocationDetails(state, locationId));
    const intl = useIntl();
    const [newCondition, setNewCondition] = useState(condition || {
        resourceTypeKey: null,
        operator: "=",
        value: 0
    });
    const [value, setValue] = useState(newCondition.value);
    const resourceTypesConfig = useSelector(selectResourcesTypesConf);
    const allResources = location ? resourceTypesConfig.filter(resource => location.resourceTypes.includes(resource.key) || location.serviceResourceTypes.includes(resource.key)) : resourceTypesConfig;
    const xsScreen = useMediaQuery(theme => theme.breakpoints.down("sm")); // xs -> sm -> md -> lg -> xl

    const renderCondition = useCallback(() => {
        const onUpdate = (data, key) => {
            if (data !== undefined) {
                const newCond = {
                    ...newCondition,
                    [key]: data
                }
                setNewCondition(newCond);
                updateAlarm(newCond, 'condition', rule, filtIndex, index);
            }
            else updateAlarm(undefined, 'condition', rule, filtIndex, index);
        }
        const operators = [">=", ">", "=", "<>", "<", "<="];

        return <Stack direction={xsScreen ? "column" : "row"} sx={{ pb: 1 }} >
            <Autocomplete
                disabled={disabled}
                sx={{ mr: 2, minWidth: '50%' }}
                fullWidth={xsScreen}
                size="small"
                id={condition !== undefined ? ("resource-" + condition.resourceTypeKey) : 'new-resource'}
                options={allResources}
                getOptionLabel={(resource) => `${intl.formatMessage({ id: resource.name })} (${intl.formatMessage({ id: "RESOURCE_TYPE.GROUP." + resource.resourceTypeGroupId.name })})`}
                value={allResources.find(el => el.key === newCondition.resourceTypeKey) || resourceTypesConfig.find(el => el.key === newCondition.resourceTypeKey)}
                onChange={(e, resource) => {
                    if (resource !== null) onUpdate(resource.key, 'resourceTypeKey');
                    else onUpdate(undefined, 'resourceTypeKey');
                }}
                renderInput={(params) => (
                    <TextField {...params} key={allResources.map(resource => resource.description)} />
                )}
                renderOption={(props, option) => (<Box {...props}><DisplayResourceName resource={option} /></Box>)}
            />
            <TextField id={"operator" + newCondition.operator + index} key={"operator" + newCondition.operator + index}
                disabled={disabled}
                sx={{ mr: 2, textAlign: 'center' }}
                fullWidth={xsScreen}
                select
                size="small"
                defaultValue={newCondition.operator}
                onChange={(e) => {
                    onUpdate(e.target.value, 'operator');
                }}
            >
                {operators.map((operator, index) => (
                    <MenuItem key={index} value={operator}>
                        {operator}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                disabled={disabled}
                fullWidth={xsScreen}
                variant="outlined"
                id={"value" + newCondition.value + index}
                key={"value" + newCondition.value + index}
                placeholder={intl.formatMessage({ id: "VALUE" })}
                type="number"
                defaultValue={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                onBlur={() => onUpdate(value, 'value')}
                size="small"
                InputProps={{
                    endAdornment: <InputAdornment position="end">{newCondition.resourceTypeKey !== null ? allResources.find(el => el.key === newCondition.resourceTypeKey) ? allResources.find(el => el.key === newCondition.resourceTypeKey).unit : "" : ""}</InputAdornment>,
                }}
            />
            <Tooltip title={<FormattedMessage id={"DELETE.CONDITION"} />} arrow>
                <IconButton onClick={() => onUpdate()}>
                    <DeleteOutlined></DeleteOutlined>
                </IconButton>
            </Tooltip>
        </Stack>;
    }, [condition, allResources, resourceTypesConfig, newCondition, value, index, filtIndex, rule, updateAlarm, disabled, intl, xsScreen])

    return renderCondition();
}