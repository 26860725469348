import instance, { setAuthHeader } from './axiosEnv';

const getLocationPoints = (token, locationId) => {
    const config = setAuthHeader(token);
    return instance.get('/pois/' + locationId, config);
}

const createPointOfInterest = (token, locationId, newPoI, imageFile) => {
    const config = setAuthHeader(token);
    const formData = new FormData();
    for (const key in newPoI) {
        if (typeof newPoI[key] !== 'string') {
            formData.append(key, JSON.stringify(newPoI[key]));
        }
        else formData.append(key, (newPoI[key]));
    }
    if (imageFile) for (const file of imageFile) {
        formData.append('picture', file, file.name);
    }

    return instance.post('/pois/' + locationId, formData, config);
}

const deletePointOfInterest = (token, id) => {
    const config = setAuthHeader(token);
    return instance.delete('/pois/' + id, config);
}

const poiAPI = {
    getLocationPoints,
    createPointOfInterest,
    deletePointOfInterest,
}

export default poiAPI;