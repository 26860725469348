import React from "react";
import { useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { selectLocationDetails } from 'redux/locationsSlice'
import { userSelector } from "redux/userSlice";
import { FormattedMessage } from 'react-intl';
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { AddSampleView, AddSampleHistoryView, LoginRequired } from "components";

function AddSamplePage(props) {
    const [searchparams] = useSearchParams();
    const locationId = searchparams.get("locationId");
    const location = useSelector((state) => selectLocationDetails(state, locationId));

    if (!locationId) return <EmptyState component={<AddSampleView />} />;
    else if (location) return <EmptyState component={<AddSampleView location={location} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} location={location}/>;
}

function AddSampleHistoryPage(props) {
    const [searchparams] = useSearchParams();
    const locationId = searchparams.get("locationId");
    const location = useSelector((state) => selectLocationDetails(state, locationId));

    if (!locationId) return <EmptyState component={<AddSampleHistoryView />} />;
    else if (location) return <EmptyState component={<AddSampleHistoryView location={location} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function AddSamplePageComponent(props) {
    const { history } = props;
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={history ? <AddSampleHistoryPage /> : <AddSamplePage />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};