import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectResourcesTypesConf } from "redux/configurationSlice";
import { selectMeasurePoints } from "redux/locationsSlice";
import { FormattedMessage } from "react-intl";
import { Autocomplete, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { AddchartOutlined, OpenInNewOutlined } from "@mui/icons-material";
import { AddSampleForm, AddSampleReport, CustomCardReport, ComplianceTag } from "components"



export default function AddSampleView(props) {
  // if opened from locationDetailsP, location prop will be sent
  // otherwise user must choose location
  const { location } = props
  const navigate = useNavigate();
  const locations = useSelector(selectMeasurePoints);
  const resourceTypesConf = useSelector(selectResourcesTypesConf);
  const [selectedLocation, setSelectedLocation] = useState(location ? location : null);
  const [resourceTypes, setResourceTypes] = useState(selectedLocation ?
    resourceTypesConf.filter(resource => selectedLocation.resourceTypes.includes(resource.key)) :
    []);
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    // update resourceTypes when location changes
    setResourceTypes(selectedLocation ?
      resourceTypesConf.filter(resource => selectedLocation.resourceTypes.includes(resource.key)) :
      []);
  }, [selectedLocation, resourceTypesConf]);


  const renderView = useCallback(() => {
    if (reportData) return (
      <CustomCardReport
        title={<div>
          <FormattedMessage id="REPORT" />
          <br />
          <ComplianceTag status={reportData.complianceIndex} />
        </div>}
        headerAction={[
          <Tooltip title={<FormattedMessage id={"FORM.ADD_SAMPLE"} />} key="addSample" placement="top-start" arrow>
            <IconButton name="addSampleButton" onClick={() => setReportData(null)}>
              <AddchartOutlined />
            </IconButton>
          </Tooltip>,
          <Tooltip title={<FormattedMessage id={"VIEW_LOCATION"} />} key="locationDetails" placement="top-start" arrow>
            <IconButton name="locationDetailsButton" onClick={() => navigate('/locations/details/' + reportData.locationId)}>
              <OpenInNewOutlined />
            </IconButton>
          </Tooltip>
        ]}
      >
        <AddSampleReport reportData={reportData} />

      </CustomCardReport>
    )
    else return (<CustomCardReport
      title={<FormattedMessage id="FORM.ADD_SAMPLE" />}
      avatar={<AddchartOutlined fontSize="small" color="primary" />}
      subtitle={location ? <Typography variant="h6"><FormattedMessage id="LOCATION" />: {location.name}</Typography> :
        <Autocomplete
          id="selectLocation"
          options={locations}
          getOptionLabel={(location) => location.name}
          value={selectedLocation}
          onChange={(e, location) => setSelectedLocation(location)}
          renderInput={(params) => (
            <TextField {...params} key={locations.map(location => location._id)} label={<FormattedMessage id="ALL_LOCATIONS" />} />
          )}
          sx={{ width: '100%' }}
          size="small"
          fullWidth
        />}

    >
      {selectedLocation ?
        <AddSampleForm
          location={selectedLocation}
          resourceTypes={resourceTypes}
          setReportData={setReportData}
          setSelectedLocation={setSelectedLocation}
        /> :
        null}
    </CustomCardReport>


    )
  }, [location, locations, navigate, resourceTypes, selectedLocation, reportData])

  return renderView();
};