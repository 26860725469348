import { useCallback } from 'react';
import { Routes, Route, Navigate } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectInitDone } from 'redux/appSlice';
import { AlarmsPage, AlarmsProfilePage, LocationsPage, MapPage, MeasurementsPage, NotesPage, SamplesPage, StagingSamplesPage, SplashPage } from 'pages';
import { PageError, PageLoading } from 'components';

import locale_en from "./lang/en.json";
import locale_hr from "./lang/hr.json";
import { IntlProvider } from "react-intl";
import './index.css';
import { AddSamplePage } from 'pages';


const languages = {
  'en': locale_en,
  'hr': locale_hr
};

export default function Display() {
  // bind to Redux to check if the data for application is ready
  const initDone = useSelector(selectInitDone);
  const language = useSelector(state => state.app).lang;

  const renderApp = useCallback(() => {
    if (initDone === 'failed') return <PageError messageId="ERROR.ERROR" />;

    else if (initDone || window.location.pathname.includes('IRI') || window.location.pathname === '/') return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/map" />} />
          <Route path="/home" element={<Navigate to="/map" />} />

          <Route path="/alarms" element={<AlarmsPage />} />
          <Route path="/alarms/details/:alarmId" element={<AlarmsPage />} />
          <Route path="/alarms/profiles" element={<AlarmsProfilePage />} />
          <Route path="/alarms/profiles/:alarmProfileId" element={<AlarmsProfilePage />} />

          <Route path="/locations" element={<LocationsPage />} />
          <Route path="/locations/group/:locationId" element={<LocationsPage group />} />
          <Route path="/locations/details/:locationId" element={<LocationsPage details />} />

          <Route path="/map" element={<MapPage />} />

          <Route path="/measurements" element={<MeasurementsPage />} />
          <Route path="/measurements/:locationId" element={<MeasurementsPage />} />

          <Route path="notes" element={<NotesPage />} />
          <Route path="notes/:locationId" element={<NotesPage />} />

          <Route path="/samples" element={<SamplesPage />} />
          <Route path="/samples/:locationId" element={<SamplesPage />} />
          <Route path="/staging-samples" element={<StagingSamplesPage staging />} />

          <Route path="/sampleEntry" element={<AddSamplePage />} />
          <Route path="/sampleEntry/:locationId" element={<AddSamplePage />} />
          <Route exact path="/sampleEntry/history" element={<AddSamplePage history />} />
          <Route exact path="/sampleEntry/history/:locationId" element={<AddSamplePage history />} />

          <Route path="/*" element={<SplashPage />} />
        </Routes>
      </BrowserRouter>
    );
    else return <PageLoading messageId="SPLASH.WAIT" />;
  }, [initDone]);


  return <IntlProvider locale={language} messages={languages[language]}>
    {renderApp()}
  </IntlProvider>
}
