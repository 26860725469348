import instance, { setAuthHeader } from './axiosEnv';
import { localISOString } from 'utils';

const getAllAlarm = (token, dateFrom = null, dateTo = null, locations = '', severities = '', selectAcknowledged = false, selectCleared = false, selectUnacknowledged = false, selectActive = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));

    for (let el in locations) {
        queryParams.append('locationId', locations[el]);
    }
    for (let el in severities) {
        queryParams.append('severity', severities[el]);
    }

    queryParams.append('acknowledged', selectAcknowledged);
    queryParams.append('unacknowledged', selectUnacknowledged);
    queryParams.append('cleared', selectCleared);
    queryParams.append('active', selectActive);

    return instance.get('/alarmHistory/all?' + queryParams.toString(), config);
}

const getSingleAlarm = (token, alarmId) => {
    const config = setAuthHeader(token);
    return instance.get('/alarmHistory/' + alarmId, config);
}

const acknowledgeAlarm = (token, alarmId, acknowledgedBy) => {
    const config = setAuthHeader(token);
    return instance.put('/alarmHistory/acknowledged/' + alarmId, acknowledgedBy, config);
}

const clearAlarm = (token, alarmId, clearedBy) => {
    const config = setAuthHeader(token);
    return instance.put('/alarmHistory/clear/' + alarmId, clearedBy, config);
}

const getAlarmProfile = (token, alarmId) => {
    const config = setAuthHeader(token);
    return instance.get('/alarmProfiles/' + alarmId, config);
}

const alarmAPI = {
    getAllAlarm,
    getSingleAlarm,
    acknowledgeAlarm,
    clearAlarm,
    getAlarmProfile,
}
export default alarmAPI;