
import { useIntl } from 'react-intl';

const DisplayResourceName = (props) => {
    const { resource } = props;
    const intl = useIntl();
    const translatedName = resource ? intl.formatMessage({ id: resource.name }) : '';
    if (resource && !resource.metas.latin) return <span>{translatedName}</span>;
    else {
        let resourceName = "";
        const parts = translatedName.split(' (');
        parts.length > 1 ?
            resourceName = <span><i>{parts[0]}</i>{" (" + parts[1]}</span> :
            resourceName = <i>{translatedName}</i>;

        return resourceName;
    }
}

export default DisplayResourceName;