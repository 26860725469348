import { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Tabs, Tab, MenuItem, Typography, Menu, Button, useMediaQuery, Box } from "@mui/material";
import { ListOutlined } from '@mui/icons-material';

function Label(props) {
    const { value } = props;
    const IDs = {
        h: "GROUPING.HOUR",
        d: "GROUPING.DAY",
        w: "GROUPING.WEEK",
        m: "GROUPING.MONTH",
    }

    if (!value || !Object.keys(IDs).includes(value)) return <FormattedMessage id="GROUPING.NO_GROUP" />
    return <FormattedMessage id={IDs[value] || "NONE"} />
}

export default function AggregationTabs(props) {
    // values should be ["h", "d", "w", "m"] or any other permutation of these elements
    const { values, setAggregation, disabled } = props;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [tabValue, setTabValue] = useState(0);
    const [agr, setAgr] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (disabled) setTabValue(false);
        else setTabValue(0);
    }, [disabled, setTabValue]);

    const handleChange = useCallback((agr, index) => {
        setTabValue(index);
        if (agr === "none") {
            setAggregation(null);
            setAgr(null);
        }
        else {
            setAggregation(agr);
            setAgr(agr);
        }
    }, [setTabValue, setAgr, setAggregation]);

    const renderTabs = useCallback(() => {
        if (!values || !values.length) return null;
        const tabs = [
            <Tab wrapped disabled={disabled} key="none" name="none" label={<Label value="none" />} />
        ];
        if (values.includes("h")) tabs.push(<Tab wrapped disabled={disabled} key="h" name="h" label={<Label value="h" />} />);
        if (values.includes("d")) tabs.push(<Tab wrapped disabled={disabled} key="d" name="d" label={<Label value="d" />} />);
        if (values.includes("w")) tabs.push(<Tab wrapped disabled={disabled} key="w" name="w" label={<Label value="w" />} />);
        if (values.includes("m")) tabs.push(<Tab wrapped disabled={disabled} key="m" name="m" label={<Label value="m" />} />);

        return tabs;
    }, [values, disabled]);

    const renderMenuItems = useCallback(() => {
        if (!values || !values.length) return null;

        const items = [
            <MenuItem key="none" name="none" onClick={(e) => handleChange("none", 0)}>
                <Typography color={!agr && "primary"}>
                    <Label value="none" />
                </Typography>
            </MenuItem>
        ];


        if (values.includes("h")) {
            items.push(
                <MenuItem key="h" name="h" onClick={(e) => handleChange("h", items.length)}>
                    <Typography color={agr === "h" && "primary"}>
                        <Label value="h" />
                    </Typography>
                </MenuItem>
            );
        }
        if (values.includes("d")) {
            items.push(
                <MenuItem key="d" name="d" onClick={(e) => handleChange("d", items.length)}>
                    <Typography color={agr === "d" && "primary"}>
                        <Label value="d" />
                    </Typography>
                </MenuItem>
            );
        }
        if (values.includes("w")) items.push(
            <MenuItem key="w" name="w" onClick={(e) => handleChange("w", items.length)}>
                <Typography color={agr === "w" && "primary"}>
                    <Label value="w" />
                </Typography>
            </MenuItem>
        );
        if (values.includes("m")) items.push(
            <MenuItem key="m" name="m" onClick={(e) => handleChange("m", items.length)}>
                <Typography color={agr === "m" && "primary"}>
                    <Label value="m" />
                </Typography>
            </MenuItem>
        );

        return items;

    }, [agr, values, handleChange]);

    return (
        smallScreen ?
            <Box>
                <Button disabled={disabled} endIcon={<ListOutlined />} onClick={handleClick} variant="text" sx={{ textTransform: 'none' }}>
                    <Typography>
                        <Label value={agr} />
                    </Typography>
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {renderMenuItems()}
                </Menu>
            </Box>
            : <Tabs value={tabValue} onChange={(e, i) => handleChange(e.target.name, i)}>
                {renderTabs()}
            </Tabs>
    );
}