import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { userSelector } from "redux/userSlice";
import { selectLanguage } from "redux/appSlice";
import { selectSeverityTypesConf } from "redux/configurationSlice";
import { selectDateRange } from "redux/dateRangeSlice";
import { FormattedMessage } from 'react-intl';
import { AlarmProfileCard, LoadingData, AlertSnackbar } from "components";
import { Alert, Container, Typography } from '@mui/material';
import API from 'api';


export default function SingleAlarmHistoryView(props) {
    const { alarmId, editFlag } = props;
    const [alarmsData, setAlarmsData] = useState(null);
    const severityTypes = useSelector(selectSeverityTypesConf);
    const selectedDateRange = useSelector(selectDateRange);
    const lang = useSelector(selectLanguage);
    const { token } = useSelector(userSelector);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setAlarmsData(null);

        API.alarmProfiles.getAlarmProfilebyId(token, alarmId)
            .then(item => {
                if (item.data) setAlarmsData(item.data);
                else setAlarmsData(null)
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                setAlarmsData({ error });
            })

    }, [token, alarmId, severityTypes, selectedDateRange, lang]);

    const render = useCallback(() => {
        if (alarmsData === null) return <LoadingData />;
        else if (alarmsData.error) return <Alert severity="error"><Typography variant='body2'><FormattedMessage id="NO_DATA" /></Typography></Alert>;
        else if (alarmsData !== null) return <AlarmProfileCard alarm={alarmsData} editFlag={editFlag} updateAlarmsData={setAlarmsData} />;
        else return <Alert severity="warning"><Typography variant='body2'><FormattedMessage id="NO_DATA_TIME_PERIOD" /></Typography></Alert>;
    }, [alarmsData, editFlag]);

    return <Container sx={{ px: 0 }}>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        {render()}
    </Container>;
};