import React from 'react';
import { Toolbar, Drawer, Card, CardHeader, CardContent, Box, IconButton, useMediaQuery } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

const SideDrawer = (props) => {
    const { state, open } = props;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return (
        <Drawer
            anchor={smallScreen ? 'top' : 'right'}
            open={open}
            onClose={() => props.toggleDrawer()}
            PaperProps={{
                sx: {
                    backgroundImage: 'none'

                }
            }}
        >
            <Box minWidth={320} maxWidth={420} sx={{ overflowX: 'hidden' }}>
                <Toolbar />
                <Card sx={{ backgroundImage: 'none' }}>
                    <CardHeader title={state.title}
                        subheader={state.subtitle}
                        action={
                            <IconButton onClick={() => props.toggleDrawer()}>
                                <CloseOutlined name="cross" />
                            </IconButton>
                        } />
                    <CardContent>
                        {state.display}
                    </CardContent>
                </Card>
            </Box >
        </Drawer >
    );
}

export default SideDrawer;