
const palette = [
    "#3366CC",
    "#DC3912",
    "#FF9900",
    "#109618",
    "#990099",
    "#3B3EAC",
    "#0099C6",
    "#DD4477",
    "#66AA00",
    "#B82E2E",
    "#316395",
    "#994499",
    "#22AA99",
    "#AAAA11",
    "#6633CC",
    "#E67300",
    "#8B0707",
    "#329262",
    "#5574A6",
    "#651067",
    "#f3a3a4",
    "#878787",
    "#976CF4",
    "#23969A",
    "#C86F6D",
    "#B98327",
    "#8087AD",
    "#774baf",
    "#3E0DA9",
    "#5B85D0",
    "#D82177",
    "#74132A",
    "#3E4461",
    "#CF4219",
    "#ff8765",
    "#5B4013",
    "#BD1F44",
    "#334455",
    "#52889b",
    "#e7ffab",
    "#a8ffff",
    "#de6069",
    "#3d9a70",
    "#dddddd",
    "#b381cc",
    "#46602a",
    "#8d9d7a",
    "#fff009",
    "#FF10F0",
];

const sampleTypePalette = {
    TYPE_UNKNOWN: "#dddddd",
    TYPE_SERVICE: "#109618",
    TYPE_SENSOR: "#3366CC",
    TYPE_MANUAL: "#fff009",
}

const statusPalette = {
    "NONE": "#dddddd",
    "COMPLIANT": "#66bb6a",
    "NON_COMPLIANT": "#f44336",
    "WARNING": "#ffa726",
    "UNKNOWN": "#ce93d8",
}

/**
 * Utility function to get next color upon each call
 */
const paletteColors = (index) => palette[index];
const sampleTypePaletteColors = (index) => Object.values(sampleTypePalette)[index];

const paletteColorsWithOpacity = (color, opacity) => { 
    // coerce values so it is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
    let opacityHex = _opacity.toString(16).toUpperCase()

    // opacities near 0 need a trailing 0
    if (opacityHex.length === 1) 
        opacityHex = "0" + opacityHex;
    return color + opacityHex;
}

export {
    palette,
    sampleTypePalette,
    statusPalette,
    paletteColors,
    paletteColorsWithOpacity,
    sampleTypePaletteColors
}