import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "redux/appSlice";
import { Avatar, Tooltip, Menu, Stack, MenuItem, Typography } from '@mui/material';


function LanguageAvatar(props) {
    const languages = {
        hr: "Hrvatski",
        gb: "English"
    }
    return <Tooltip title={languages[props.lang]} placement="left" arrow>
        <Avatar onClick={props.onClick} sx={theme => ({ width: 40, height: 40, cursor: 'pointer', border: `2px solid ${props.active ? theme.palette.primary.main : 'transparent'}` })} alt={props.lang} src={`https://raw.githubusercontent.com/hampusborgos/country-flags/main/png250px/${props.lang}.png`} />
    </Tooltip>
}

export default function LanguageSelect(props) {
    const { openLang, setOpenLang, anchorLang } = props;
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const handleLangChange = (lang) => {
        setOpenLang(false);
        dispatch(setLanguage(lang))
    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorLang}
            open={openLang}
            onClose={() => setOpenLang(false)}
        >

            <MenuItem onClick={() => handleLangChange("en")}>
            <Stack spacing={2} direction='row' justifyContent="center" alignItems="center">
                <LanguageAvatar active={app.lang === "en"} lang="gb" onClick={() => handleLangChange("en")} />
                <Typography ><FormattedMessage id="LANGUAGE.en" /></Typography>
            </Stack>
            </MenuItem>

            <MenuItem onClick={() => handleLangChange("hr")}>
            <Stack spacing={2} direction='row' justifyContent="center" alignItems="center">
                <LanguageAvatar active={app.lang === "hr"} lang="hr" />
                <Typography><FormattedMessage id="LANGUAGE.hr" /></Typography>
            </Stack>
            </MenuItem> 

        </Menu>
                    
    );
};