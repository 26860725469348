import { FormattedMessage } from 'react-intl';
import { keycloak } from "api/AuthKeycloak";
import { Backdrop, Typography, Box } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

export default function PageError(props) {
    const { messageId } = props;
    if (keycloak.authenticated) keycloak.logout();
    return (
        <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <ErrorOutline fontSize='large' color="inherit" />
                {messageId && <Typography variant="body1" color="inherit">
                    <FormattedMessage id={messageId} />
                </Typography>}
            </Box>
        </Backdrop>
    );
}