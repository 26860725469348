import React, { useCallback, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { AlarmProfileTable, LoadingData, AlertSnackbar } from "components";
import { Alert, Card, CardContent, CardHeader, Typography } from '@mui/material';
import API from 'api';


export default function AlarmProfileTableContainer(props) {
    const { locations, nameIncludes, severityTypesSelected, selectedNotificationTypes, selectEnabled, selectInactive, selectTriggered, displayAll } = props;
    const intl = useIntl();
    const [alarmsData, setAlarmsData] = useState(null);
    const { token } = useSelector(userSelector);
    const [alert, setAlert] = useState({ open: false });
    const [loading, setLoading] = useState(false);

    const onAlertClose = () => setAlert({ ...alert, open: false });


    useEffect(() => {
        setLoading(true);
        API.alarmProfiles.getAllAlarmProfiles(token, locations, nameIncludes, severityTypesSelected, selectedNotificationTypes, selectEnabled, selectTriggered, displayAll)
            .then(items => {
                if (items && items.data) {
                    const data = items.data.map(item => {
                        return {
                            ...item,
                            id: item._id,
                            triggered: Boolean(item.triggered),
                            name: item.name,
                            rulesArray: Object.keys(item.rules || {})
                        }
                    })
                    setAlarmsData(data);
                }
                else setAlarmsData([]);
                setLoading(false);
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                setAlarmsData({ error });
                setLoading(false);
            });

    }, [token, severityTypesSelected, selectTriggered, selectEnabled, selectedNotificationTypes, selectInactive, locations, nameIncludes, intl, displayAll]);

    const render = useCallback(() => {
        const updateData = (newData) => {
            setAlarmsData(alarmsData.map(profile => {
                if (newData._id === profile._id) return {
                    ...newData,
                    id: newData._id,
                    triggered: Boolean(newData.triggered),
                    name: newData.name,
                    rulesArray: Object.keys(newData.rules)
                };
                else return profile;
            }))
        }
        if (loading) return <LoadingData />;
        else if (alarmsData && alarmsData.length) return <AlarmProfileTable data={alarmsData} updateAlarmsData={updateData} />
        else if (alarmsData && alarmsData.error) return <Alert severity="error"><Typography variant='body2'><FormattedMessage id="NO_DATA" /></Typography></Alert>;
        else return <Alert severity="warning"><Typography variant='body2'><FormattedMessage id="NO_DATA_SELECTED_FILTER" /></Typography></Alert >;
    }, [alarmsData, loading]);

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Card>
            <CardHeader title={<FormattedMessage id="ALARM.PROFILES.TABLE" />} />
            <CardContent>
                {render()}
            </CardContent>
        </Card>
    </>;
};