import instance, { setAuthHeader } from './axiosEnv';

const getAllNotes = (token, locationId) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }

    return instance.get('/notes?' + queryParams.toString(), config);
}

const getSingleNote = (token, noteId) => {
    const config = setAuthHeader(token);
    return instance.get('/notes/' + noteId, config);
}

const postNote = (token, noteObject) => {
    const config = setAuthHeader(token);
    return instance.post('/notes', noteObject, config);
}

const archiveNote = (token, noteIdArray) => {
    const config = setAuthHeader(token);
    return instance.put('/notes/archive', noteIdArray, config);
}

const unarchiveNote = (token, noteIdArray) => {
    const config = setAuthHeader(token);
    return instance.put('/notes/unarchive', noteIdArray, config);
}

const resendNote = (token, noteId) => {
    const config = setAuthHeader(token);
    return instance.put('/notes/resend/' + noteId, config);
}

const notesAPI = {
    getAllNotes,
    getSingleNote,
    postNote,
    archiveNote,
    unarchiveNote,
    resendNote
}

export default notesAPI;
