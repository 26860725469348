import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { setFontSize, setFontWeight } from "redux/appSlice";
import { Typography, Menu, Slider, Stack, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { TextDecrease, TextIncrease, TableRows, RestartAltOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';




export default function FormatSelect(props) {
    const { anchorEl, format, setFormat } = props;
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const [fSize, setFSize] = useState(app.fontSize);
    const [fWeight, setFWeight] = useState(app.fontWeight);
  

    const handleClear = () => {
        setFWeight(0)
        dispatch(setFontWeight(0))
        setFSize(14)
        dispatch(setFontSize(14))
    };

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={format}
            onClose={() => setFormat(false)}
        >
            <MenuItem>
                <Stack direction="column">
                    <Typography gutterBottom><FormattedMessage id="FONT_SIZE" /></Typography>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <TextDecrease />
                        <Slider aria-label="font-size"
                            size='small'
                            sx={{ width: 100 }}
                            defaultValue={fSize}
                            value={fSize}
                            onChange={(event, value) => {
                                setFSize(value)
                                dispatch(setFontSize(value))
                            }}
                            min={10}
                            max={20}
                            step={2}
                            marks
                            valueLabelDisplay='auto' />
                        <TextIncrease />
                    </Stack>
                </Stack>
            </MenuItem>
            <MenuItem>
                <Stack direction="column">
                    <Typography gutterBottom><FormattedMessage id="FONT_WEIGHT" /></Typography>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <MenuIcon />
                        <Slider aria-label="font-weight"
                            size='small'
                            sx={{ width: 100 }}
                            defaultValue={fWeight}
                            value={fWeight}
                            onChange={(event, value) => {
                                setFWeight(value)
                                dispatch(setFontWeight(value))
                            }}
                            min={-100}
                            max={200}
                            step={50}
                            marks
                            valueLabelDisplay='auto' />
                        <TableRows />
                    </Stack>
                </Stack>
            </MenuItem>
            <MenuItem onClick={handleClear} sx={{ backgroundColor: 'transparent' }}>
                <ListItemIcon>
                    <RestartAltOutlined color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText disableTypography>
                    <Typography variant="button" color="primary">
                        <FormattedMessage id="RESET" />
                    </Typography>
                </ListItemText>
            </MenuItem>
        </Menu>
    );
};