import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectResourcesTypesConf } from "redux/configurationSlice";
import { selectAllLocations } from 'redux/locationsSlice';
import { Box, Divider, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { formatDateLocale } from 'utils';
import { ComplianceTag, DisplayResourceName } from 'components';


export default function AddSampleHistoryReport(props) {
    const { reportData, page } = props;
    const locations = useSelector(selectAllLocations);
    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const renderSampleLog = useCallback(() => {
        const row = reportData.samplingLogsArray[page - 1];
        return <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ pt: '0 !important' }}>
                <ComplianceTag status={row.complianceIndex} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography color="info.main" variant="subtitle1"><FormattedMessage id="SAMPLE_LOG" /> {page}</Typography>
                <Typography color="text.secondary" variant="subtitle2"><FormattedMessage id="TIMESTAMP" /></Typography>
                <Typography variant="h6">{formatDateLocale(row.timestamp)}</Typography>
                <Typography color="text.secondary" variant="subtitle2"><FormattedMessage id="LOCATION" /></Typography>
                <Typography variant="h6">{locations.find(loc=>loc._id===row.locationId).name}</Typography>
                <Typography color="text.secondary" variant="subtitle2"><FormattedMessage id="SAMPLE_LOG.COLLECTOR" /></Typography>
                <Typography variant="h6">{row.collector}</Typography>
                <Typography color="text.secondary" variant="subtitle2"><FormattedMessage id="NOTES" /></Typography>
                <Typography variant="h6">{row.notes}</Typography>
            </Grid>
            {!!row.errors.number && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography color="error.main" variant="subtitle1"><FormattedMessage id="INVALID_COLUMNS" /> ({row.errors.number})</Typography>
                {row.errors.array.map((el, i) => <Box key={el.column + i} sx={{ display: "inline-flex", width: "100%" }}>
                    <Box sx={{ width: "60%" }}>
                        <Typography color="text.secondary" variant="subtitle2"><FormattedMessage id="HEADER" /></Typography>
                        <Typography variant="h6">{el.column}</Typography>
                    </Box>
                    <Box sx={{ width: "40%" }}>
                        <Typography color="text.secondary" variant="subtitle2"><FormattedMessage id="VALUE" /></Typography>
                        <Typography variant="h6">{el.value}</Typography>
                    </Box>
                </Box>)}
                <Divider />
            </Grid>}
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 3 }}>
                {row.data.map(el => {
                    const resType = resourceTypesConf.find(res => res.key === el.resourceTypeKey);
                    return <Grid key={"data-" + el.resourceTypeKey} item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Typography color="text.secondary" variant="subtitle2"><DisplayResourceName resource={resType} /></Typography>
                        <Typography color="primary" variant="h4" display="inline">{el.value}</Typography>
                        <Typography variant="body2" display="inline">{resType.unit}</Typography>
                    </Grid>
                })}
            </Grid>
        </Grid>
    }, [reportData, page, resourceTypesConf,locations])

    return <Box>
        {reportData.samplingLogsArray.length ? renderSampleLog() : <Typography><FormattedMessage id="NO_VALID_LOGS" /></Typography>}
    </Box>;
}