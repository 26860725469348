import instance, { setAuthHeader } from './axiosEnv';

const getAllLocations = () => {
    return instance.get('/locations');
}

const getAllLocationData = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/locations/all', config);
}

const getAllGeoFeatures = () => {
    return instance.get('/locations/geoFeatures');
}

const getAllGeoFeaturesData = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/locations/geoFeatures/all', config);
}

const getStatusCode = (locationId) => {
    return instance.get(`/locations/${locationId}/complianceIndex`);
}

const createFormDataHelper = (newLocationObject, imageFile) => {
    const formData = new FormData();
    for (const key in newLocationObject) {
        if (typeof newLocationObject[key] !== 'string') {
            formData.append(key, JSON.stringify(newLocationObject[key]));
        }
        else formData.append(key, (newLocationObject[key]));
    }
    if (imageFile) for (const file of imageFile) {
        formData.append('picture', file, file.name);
    }

    return formData;
}

const updateLocation = (token, locationId, newLocationObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/' + locationId, createFormDataHelper(newLocationObject, imageFile), config);
}

const updateLocationGroup = (token, locationGroupId, newLocationGroupObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/group/' + locationGroupId, createFormDataHelper(newLocationGroupObject, imageFile), config);
}

const createLocation = (token, newLocationObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.post('/locations', createFormDataHelper(newLocationObject, imageFile), config);
}

const createLocationGroup = (token, newLocationGroupObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.post('/locations/group', createFormDataHelper(newLocationGroupObject, imageFile), config);
}

const locationsAPI = {
    // public locations + mp 
    getAllLocations,
    // locations + measure points
    getAllLocationData,
    // all geoJSON in organization 
    getAllGeoFeatures,
    // geoJSONs for logged in user
    getAllGeoFeaturesData,
    // get location's status code
    getStatusCode,
    // update location info
    updateLocation,
    // update group
    updateLocationGroup,
    // create new location
    createLocation,
    // create new group
    createLocationGroup,
}

export default locationsAPI;
