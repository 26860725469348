import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectResourcesTypesConf, selectSamplingTypesConf } from 'redux/configurationSlice';
import { selectLocationDetails } from 'redux/locationsSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { useNavigate } from 'react-router-dom';
import { CustomDataGrid, DisplayResourceName, LoadingData, SamplingLogDetails, SideDrawer, AlertSnackbar, ComplianceTag } from 'components';
import { formatDateLocale, exportData } from 'utils';

import { Alert, Button, Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Tooltip, useMediaQuery } from '@mui/material';
import { OpenInNewOutlined, FileDownloadOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import API from 'api';

function MeasurementsTableContainer(props) {
    const { locationId, sampleTypes, resourceTypes, selectedMeasurePointTypes } = props;
    const { token } = useSelector(userSelector);
    const location = useSelector((state) => selectLocationDetails(state, locationId));
    const selectedDateRange = useSelector(selectDateRange);
    const measurementsSampleTypes = useSelector(selectSamplingTypesConf);
    const resourcesTypes = useSelector(selectResourcesTypesConf);
    const navigate = useNavigate();
    const intl = useIntl();
    const [measurementData, setMeasurementData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [details, setDetails] = useState({});
    const [detailsDrawer, setDetailsDrawer] = useState(false);
    const [openDrawer, setDrawer] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(25);
    const [total, setTotal] = useState();

    const onAlertClose = () => setAlert({ ...alert, open: false });
    const exportHeaders = [
        intl.formatMessage({ id: 'SAMPLING_LOG_REPORT.LOCATION_NAME' }),
        intl.formatMessage({ id: 'DATE' }),
        intl.formatMessage({ id: 'SAMPLING_LOG_REPORT.SAMPLE_TYPE' }),
        intl.formatMessage({ id: 'SAMPLING_LOG_REPORT.MEASUREMENT_TYPE' }),
        intl.formatMessage({ id: 'SAMPLING_LOG_REPORT.VALUE' })
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExportChange = (option, print) => {
        exportData({ [option]: true }, location.name, intl.formatMessage({ id: 'MEASUREMENTS' }), tableData, exportHeaders, undefined, print);
        handleClose();
    }

    useEffect(() => {
        setMeasurementData(null);
        handleClose();
        API.measurements.getMeasurementsHistory(token, [location._id], resourceTypes, selectedDateRange.dateFrom, selectedDateRange.dateTo, sampleTypes, selectedMeasurePointTypes, page, size)
            .then(items => {
                if (items.data) {
                    const data = items.data.docs.map(collection => {
                        const sampleType = measurementsSampleTypes.find(el => el.key === collection.samplingType).value;
                        const resourceType = resourcesTypes.find(el => el.key === collection.resourceTypeKey);
                        return {
                            id: collection._id,
                            samplingLogId: collection.samplingLogId,
                            locationName: location.name,
                            date: formatDateLocale(collection.timestamp),
                            sampleType: intl.formatMessage({ id: 'SAMPLE_LOG.TYPE_' + sampleType }),
                            measurementType: intl.formatMessage({ id: resourceType.name }),
                            value: collection.value,
                            resourceType: resourceType
                        }
                    });
                    setTotal(items.data.totalDocs)
                    setMeasurementData(data);
                }
                else setMeasurementData([]);
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                setMeasurementData({ error });
            });
    }, [token, location, resourceTypes, resourcesTypes, sampleTypes, selectedDateRange, measurementsSampleTypes, selectedMeasurePointTypes,intl, page, size]);

    useEffect(() => {
        if (detailsDrawer) {
            try {
                setDetails({
                    title: <FormattedMessage id='SAMPLE_LOG' />,
                    subtitle: <ComplianceTag status={detailsDrawer.statusCode.COMPLIANCE} />,
                    display: <SamplingLogDetails timestamp={formatDateLocale(detailsDrawer.timestamp)} locationName={detailsDrawer.locationName} locationId={detailsDrawer.locationId} logId={detailsDrawer._id} sampleType={detailsDrawer.sampleType} values={detailsDrawer.valuesArray} notes={detailsDrawer.notes} pictures={detailsDrawer.pictures} collector={detailsDrawer.source.collector} />
                });
            } catch (err) {
                console.error(err);
                setAlert({ open: true, messageId: "ERROR.ERROR" });
                setDetails({
                    title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
                    subtitle: '',
                    display: <div />
                });
            }
        }
    }, [measurementData, detailsDrawer])

    useEffect(() => {
        setPage(0)
    }, [selectedDateRange, resourceTypes, sampleTypes, selectedMeasurePointTypes])

    const render = useCallback(() => {
        const handleData = (row) => {
            setDrawer(true);
            setDetails({
                title: <FormattedMessage id={'LOADING_DATA'} />,
                subtitle: <FormattedMessage id='WAIT' />,
                display: <LoadingData noText />
            });
            API.samplingLogs.getSamplingDetails(token, row.row.samplingLogId)
                .then(({ data }) => {
                    const sampleType = measurementsSampleTypes.find(el => el.key === data.source.type).value;
                    const valuesArray = [];
                    for (let index = 0; index < data.measurements.length; index++) {
                        const displayData = data.measurements[index];
                        const resource = resourcesTypes.find(el => el.key === displayData.resourceTypeKey);
                        valuesArray.push({
                            id: resource.key,
                            resource: <DisplayResourceName resource={resource} />,
                            value: displayData.value,
                            unit: resource.unit
                        });
                    }
                    setDetailsDrawer({ ...data, locationName: row.row.locationName, sampleType: sampleType, valuesArray: valuesArray });
                })
                .catch((error) => {
                    setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                    setDetails({
                        title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
                        subtitle: '',
                        display: <div />
                    });
                });
        }

        const tableHeaders = [
            {
                field: 'locationName',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.LOCATION_NAME" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'date',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.DATE" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'sampleType',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.SAMPLE_TYPE" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            },
            {
                field: 'measurementType',
                headerName: intl.formatMessage({ id: "TYPE_OF_MEASUREMENTS" }),
                renderCell: (row) => <DisplayResourceName resource={row.row.resourceType} />,
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'value',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.VALUE" }),
                renderCell: (row) => <>{row.row.value + " " + row.row.resourceType.unit}</>,
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            },
        ];

        if (measurementData === null) return <LoadingData />;
        if (measurementData.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else if (measurementData.length)  
            return <CustomDataGrid 
                rows={measurementData} 
                columns={tableHeaders}
                handleData={handleData} 
                rowCount={total} 
                page={page} 
                onPageChange={(newPage) => {setPage(newPage)}} 
                size={size} 
                onPageSizeChange={(newPageSize) => setSize(newPageSize)}
                paginationMode='server' 
            />;
        else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>;
    }, [measurementData, intl, measurementsSampleTypes, resourcesTypes, token, smallScreen, page, size, total]);

    const tableData = measurementData !== null && measurementData.length ? measurementData.map(el => [el.locationName, el.date, el.sampleType, el.measurementType, el.value]) : null;

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Card>
            <CardHeader
                title={<span><FormattedMessage id="DATA_TABLE" /> ({location.name})</span>}
                action={[
                    ...[tableData !== null && !!tableData.length ? <Button
                        id="export-button"
                        key="export-button"
                        aria-controls={open ? 'export-data-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        size="small"
                        disableElevation
                        onClick={handleClick}
                        startIcon={smallScreen ? null : <FileDownloadOutlined />}
                        endIcon={open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                    >
                        <FormattedMessage id="EXPORT" />
                    </Button> : null],
                    <Menu
                        id="export-data-menu"
                        key='export-data-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={(e) => handleExportChange('getJsonData')} ><FormattedMessage id="EXPORT_JSON" /></MenuItem>
                        <MenuItem onClick={(e) => handleExportChange('getCsvData')} ><FormattedMessage id="EXPORT_CSV" /></MenuItem>
                        <MenuItem onClick={(e) => handleExportChange('getPdfData')} ><FormattedMessage id="EXPORT_PDF" /></MenuItem>
                        <MenuItem onClick={(e) => handleExportChange('getPdfData', true)} > <FormattedMessage id="PRINT_PDF" /></MenuItem>
                    </Menu>,
                    <Tooltip title={<FormattedMessage id={"LOCATION.OPEN"} />} key="details" position="left" arrow>
                        <IconButton name="open" onClick={() => navigate('/locations/details/' + location._id)}><OpenInNewOutlined fontSize="small" /></IconButton>
                    </Tooltip>
                ]}
            />
            <CardContent>
                {render()}
                <SideDrawer state={details} open={openDrawer} toggleDrawer={() => setDrawer(false)} />
            </CardContent>
        </Card>
    </>;
};

export default MeasurementsTableContainer;