import React, { useEffect, useRef, useState, cloneElement, Children, isValidElement } from 'react';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { Cluster, OSM, Vector as VectorSource } from 'ol/source.js';
import {
    Circle as CircleStyle,
    Fill,
    Stroke,
    Style,
    Text,
} from 'ol/style.js';
import { Point, LineString } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { defaults } from 'ol/control/defaults';
import { useTheme } from '@mui/material';
import { MapPopup, MARKERS } from 'components';
import { useSelector } from 'react-redux';
import { selectFontSize, selectFontWeight } from 'redux/appSlice';
import { selectMapProps } from 'redux/mapSlice';
import { selectMeasurePoints, selectLocations } from 'redux/locationsSlice';
import './mapStyle.css';
import MapGeoJSON from 'components/mapGeoJSON/MapGeoJSON';

// SHAPE FILE LAYERS
const geoJSONLayer = new VectorLayer({
    source: new VectorSource({})
});

export default function MapComponent(props) {
    const { position, zoom, className, children, customFeatures, disableInteraction, disableShapes, onClick, onUpdate } = props;
    const mapProps = useSelector(selectMapProps);
    const [coord] = useState([fromLonLat(mapProps.maxBounds[0]), fromLonLat(mapProps.maxBounds[1])])
    const [customClass, setCustomClass] = useState('map-main-container');
    const ref = useRef();
    const measurePoints = useSelector(selectMeasurePoints);
    const locations = useSelector(selectLocations);
    const theme = useTheme();
    const fontSize = useSelector(selectFontSize);
    const fontWeight = useSelector(selectFontWeight);
    const clusterFontWeight = fontWeight < 100 ? 'normal' : 'bold';


    //CONECTIONS
    const featureConnections = measurePoints.map((mPoint) => {
        return new Feature({
            geometry: new LineString([fromLonLat(mPoint.connection[0]), fromLonLat(mPoint.connection[1])]),
            name: 'Connections'
        });
    });
    //STYLE
    featureConnections.forEach((con) => con.setStyle(MARKERS.connection(theme.palette.mode)));

    //LOCATIONS AND MEASURE POINTS
    const measureFeature = measurePoints.map((loc) => {
        return new Feature({
            geometry: new Point(fromLonLat(loc.position)),
            name: loc.name,
            type: "LOCATION",
            object: loc
        });
    })

    const locationFeature = locations.map((loc) => {
        return new Feature({
            geometry: new Point(fromLonLat(loc.position)),
            name: loc.name,
            type: "LOCATION_GROUP",
            object: loc
        });
    })
    //STYLE
    measureFeature.forEach((feature) => feature.setStyle(MARKERS.measurePoint(theme.palette.mode)))
    locationFeature.forEach((feature) => feature.setStyle(MARKERS.location(theme.palette.mode)))

    //ADD CONNECTIONS AND PINS AS LAYER SOURCE FEATURES
    const vectorSource = new VectorSource({
        features: [...measureFeature, ...locationFeature, ...featureConnections],
    });


    const vectorLayer = new VectorLayer();
    if (customFeatures && customFeatures.length) {
        vectorSource.addFeatures(customFeatures);
    }
    vectorLayer.setSource(vectorSource);

    
    const clusterSource = new Cluster({
        distance: 25,
        minDistance: 100,
        source: new VectorSource({
            features: [...measureFeature],
        }),
    });

    const styleCache = {};
    const clusters = new VectorLayer({
        source: clusterSource,
        style: function (feature) {
            const size = feature.get('features').length;
            let style = styleCache[size];
            if (!style) {
                style = new Style({
                    image: new CircleStyle({
                        radius: 12,
                        stroke: new Stroke({
                            color: '#fff',
                        }),
                        fill: new Fill({
                            color: '#1976d2',
                        }),
                    }),
                    text: new Text({
                        text: size.toString(),
                        fill: new Fill({
                            color: '#fff',
                        }),
                        justify: 'center',
                        textAlign: 'center',
                        offsetY: 1,
                        font: `${fontSize}px ${clusterFontWeight} sans-serif`
                    }),
                });
                styleCache[size] = style;
            }
            return style;
        },
    });

    //MAP
    const [theMap] = useState(new Map({
        showFullExtent: true,
        layers: [
            // Base Layer - OpenStreetMap
            new TileLayer({
                source: new OSM(),
            }),
            geoJSONLayer,
            // clusters/vectorLayer with location pins has to be last in array
            zoom ? vectorLayer : clusters
        ],
        view: new View({
            center: position ? fromLonLat(position) : fromLonLat([mapProps.defaultY, mapProps.defaultX]),
            zoom: zoom ? zoom : mapProps.zoomLevel,
            minZoom: parseInt(mapProps.minZoom),
            maxZoom: parseInt(mapProps.maxZoom),
            extent: coord.flat()
        }),
        controls: defaults({
            zoom: !disableInteraction
        })
    }));
    theMap.getInteractions().forEach(i => {
        i.setActive(!disableInteraction)
    });

    theMap.on('moveend', (event) => {
        const newZoomLevel = (theMap.getView().getZoom());
        if (newZoomLevel > mapProps.zoomThreshold) {
            // remove Clusters Layer
            theMap.removeLayer(theMap.getAllLayers().pop());
            // add Vector Layer with location pins
            theMap.addLayer(vectorLayer);

        }

        if (newZoomLevel < mapProps.zoomThreshold) {
            // remove Vector Layer
            theMap.removeLayer(theMap.getAllLayers().pop());
            // add Clusters Layers
            theMap.addLayer(clusters);
        }
    });

    theMap.on("pointermove", function (evt) {
        let hit = theMap.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            return feature.get('name') !== 'Connections';
        });
        if (hit) theMap.getTargetElement().style.cursor = 'pointer';
        else if (theMap.getTargetElement().style.cursor !== 'grab') theMap.getTargetElement().style.cursor = '';
    });
    theMap.on("pointerup", function (evt) {
        theMap.getTargetElement().style.cursor = 'default';
    });
    theMap.on("pointerdown", function (evt) {
        let hit = theMap.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            return feature && feature.get('name') !== 'Connections';
        });
        if (hit) theMap.getTargetElement().style.cursor = 'pointer';
        else theMap.getTargetElement().style.cursor = 'grab';
    });
    theMap.on("pointerdrag", function (evt) {
        theMap.getTargetElement().style.cursor = 'grabbing';
    });

    useEffect(() => {
        const clickCB = (event) => onClick(event);
        if (typeof onClick === 'function') theMap.on('singleclick', clickCB);
        return () => theMap.un('singleclick', clickCB);
    }, [onClick, theMap])

    useEffect(() => {
        if (ref.current) {
            theMap.setTarget(ref.current)
        }

        return () => theMap.setTarget(undefined);
    }, [ref, theMap, zoom]);

    useEffect(() => {
        const mapClass = "map-main-container";
        if (className !== mapClass && !!className) setCustomClass(mapClass + ' ' + className);
        else setCustomClass(mapClass);
    }, [className, setCustomClass]);

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { map: theMap });
        }
        return child;
    });

    const removeFeature = (id) => {
        const feature = customFeatures.find(feature => feature.get('object')._id === id);
        vectorLayer.getSource().removeFeature(feature)
    }

    return (<div ref={ref} className={customClass}>
        <MapPopup disabled={disableInteraction} theMap={theMap} onUpdate={onUpdate} onDelete={removeFeature} />
        <MapGeoJSON disabled={disableShapes} theMap={theMap} geoJSONLayer={geoJSONLayer} />
        {children ? childrenWithProps : null}
    </div >);

}