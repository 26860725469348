import { useState, useRef, useEffect } from "react";
import { Box, Tooltip, Typography, Pagination, useMediaQuery } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { DisplayResourceName } from "components";
import { selectResourcesTypesConf } from "redux/configurationSlice";
import { formatDateLocale } from 'utils';


function SingleKPI(props) {
    const { resourceType, value, timestamp } = props;
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const ref = useRef();
    const [overflow, setOverflow] = useState(false);

    useEffect(() => {
        if (ref.current.offsetHeight < ref.current.scrollHeight || ref.current.offsetWidth < ref.current.scrollWidth) setOverflow(true);
    }, [ref, overflow]);
    /**
     * screen size is XS - KPI width is 100%
     * screen size is SM - KPI width is 50%
     * screen size is MD - KPI width is 33%
     * screen size is LG - KPI width is 25%
     */
    const width = smallScreen ? '50%' : mediumScreen ? '33%' : '25%';

    return <Box sx={{ display: 'inline-block', width: width, pb: 4, pr: 6, cursor: 'default' }}>
        <Tooltip title={overflow ? <FormattedMessage id={resourceType.name} /> : ""} placement="top">
            <Typography ref={ref} variant="subtitle2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <DisplayResourceName resource={resourceType} />
            </Typography>
        </Tooltip>
        <Typography color="primary.main" variant="h4" display="inline">{value}</Typography>
        <Typography variant="body1" display="inline">{resourceType.unit}</Typography>
        <Typography color="text.secondary" variant="caption" display="block">{formatDateLocale(timestamp)}</Typography>
    </Box>
}

export default function MeasurementKPIs(props) {
    const { values, filter } = props;
    const [page, setPage] = useState(1);
    const intl = useIntl();
    const resourceTypes = useSelector(selectResourcesTypesConf);
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

    useEffect(() => {
        if (filter !== null) setPage(1);
    }, [filter, setPage]);

    const handleChange = (e, value) => setPage(value);

    const valuesArray = values.map((data, index) => {
        const resourceType = resourceTypes.find(el => el.key === data.resourceKey);
        if (resourceType) {
            if (filter.text) {
                const resName = !filter.caseSensitive ? intl.formatMessage({ id: resourceType.name }).toUpperCase() : intl.formatMessage({ id: resourceType.name });
                const filterName = !filter.caseSensitive ? filter.text.toUpperCase() : filter.text;
                if (!resName.includes(filterName)) return null;
            }

            const value = data.value;
            return <SingleKPI key={index} index={index} resourceType={resourceType} value={value} timestamp={data.timestamp} />
        }
        else return null;

    }).filter(el => el !== null);

    const perPageNumber = (() => {
        if (smallScreen) return 4;
        else if (mediumScreen) return 6;
        else return 8
    })();
    const pageCount = Math.ceil(valuesArray.length / perPageNumber);

    return <div>
        <div style={{ minHeight: '250px' }}>
            {valuesArray.slice((page - 1) * perPageNumber, ((page - 1) * perPageNumber) + perPageNumber)}
        </div>
        {valuesArray.length > perPageNumber && <Pagination count={pageCount} page={page} onChange={handleChange} showFirstButton showLastButton />}
    </div>

}